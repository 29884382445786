import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clients: {}, // Store clients as an object keyed by `client_id`
  selected_client_id: null, // Tracks the currently selected client by `client_id`
  selected_client_details: {
    first_name: '',
    last_name: '',
    middle_name: '',
    date_of_birth: null,
    referred_by: '',
    email: '',
    created_at: null,
    updated_at: null,
  },
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClients: (state, action) => {
      // Store clients as an object for easier access
      const clientsArray = action.payload;
      state.clients = clientsArray.reduce((acc, client) => {
        acc[client.client_id] = client;
        return acc;
      }, {});
    },
    addClient: (state, action) => {
      const newClient = action.payload;
      state.clients[newClient.client_id] = newClient; // Add new client to state
    },
    updateSelectedClient: (state, action) => {
      const clientId = action.payload;
      state.selected_client_id = clientId;
      if (state.clients[clientId]) {
        state.selected_client_details = { ...state.clients[clientId] };
      }
    },
    updateClientFirstName: (state, action) => {
      state.selected_client_details.first_name = action.payload;
    },
    updateClientLastName: (state, action) => {
      state.selected_client_details.last_name = action.payload;
    },
    updateClientMiddleName: (state, action) => {
      state.selected_client_details.middle_name = action.payload;
    },
    updateClientDateOfBirth: (state, action) => {
      state.selected_client_details.date_of_birth = action.payload;
    },
    updateClientReferredBy: (state, action) => {
      state.selected_client_details.referred_by = action.payload;
    },
    updateClientEmail: (state, action) => {
      state.selected_client_details.email = action.payload;
    },
    updateClientCreatedAt: (state, action) => {
      state.selected_client_details.created_at = action.payload;
    },
    updateClientUpdatedAt: (state, action) => {
      state.selected_client_details.updated_at = action.payload;
    },
    deleteClient: (state, action) => {
      const clientId = action.payload;
      delete state.clients[clientId]; // Remove client from state
      if (state.selected_client_id === clientId) {
        state.selected_client_id = null; // Clear selected client if deleted
        state.selected_client_details = {
          first_name: '',
          last_name: '',
          middle_name: '',
          date_of_birth: null,
          referred_by: '',
          email: '',
          created_at: null,
          updated_at: null,
        };
      }
    },
    addNewClient: (state, action) => {
      const newClient = action.payload;
      // Add the client
      state.clients[newClient.client_id] = newClient;
      // Set the newly added client as the selected client
      state.selected_client_id = newClient.id;
      console.log("NEW CLIENT ADDED AND SELECTED: ", newClient.id);
      state.selected_client_details = { ...newClient };
    },
    reset: () => initialState,
  },
});

export const {
  setClients,
  addClient,
  updateSelectedClient,
  updateClientFirstName,
  updateClientLastName,
  updateClientMiddleName,
  updateClientDateOfBirth,
  updateClientReferredBy,
  updateClientEmail,
  updateClientCreatedAt,
  updateClientUpdatedAt,
  deleteClient,
  addNewClient,
  reset,
} = clientSlice.actions;

export default clientSlice.reducer;
