import React, { useState } from 'react';
import './HelpAndFeedback.css';

const HelpAndFeedback = () => {
    const [feedback, setFeedback] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    const handleFeedbackSubmit = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/feedback`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ feedback }),
            });

            if (response.ok) {
                setStatusMessage('Feedback submitted successfully.');
                setFeedback('');
            } else {
                const errorData = await response.json();
                setStatusMessage(`Failed to submit feedback: ${errorData.msg}`);
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setStatusMessage('An error occurred while submitting feedback.');
        }
    };

    return (
        <div className="help-and-feedback-container">
            <h1 className="help-title">Help and Feedback</h1>
            <div className="help-section">
                <h3>FAQs</h3>
                <p>
                    Visit our <a href="/faq" target="_blank" rel="noopener noreferrer" className="faq-link">Frequently Asked Questions</a> page for answers to common questions.
                </p>
            </div>
            <div className="feedback-section">
                <h3>Submit Feedback</h3>
                <textarea
                    className="feedback-textarea"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Write your feedback here..."
                    rows="5"
                />
                <button className="feedback-submit-button" onClick={handleFeedbackSubmit}>
                    Submit Feedback
                </button>
                {statusMessage && <p className="feedback-status-message">{statusMessage}</p>}
            </div>
        </div>
    );
};

export default HelpAndFeedback;
