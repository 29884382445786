import { setLoading } from '../state/loading/loadingSlice';
import { updateUserId } from '../state/user/userSlice';
import { setContactInfo } from '../state/user/userContactSlice';
import { fetchUserContact } from '../apiFetch/fetchUserContact';

const onLogin = (store) => (next) => async (action) => {
  if (action.type === updateUserId.type) {
    const { user_id } = action.payload;
    if (user_id) {
      try {
        // Start loading
        // store.dispatch(setLoading(true));
        // Fetch user contact information
        const contactInfo = await fetchUserContact(user_id);
        if (contactInfo) {
          store.dispatch(setContactInfo(contactInfo)); // Dispatch action to set contact info
        } else {
          console.warn('Middleware: No contact info returned for user_id:', user_id);
        }
      } catch (error) {
        console.error('Middleware: Error fetching user contact info:', error);
      } finally {
        // Stop loading
        // store.dispatch(setLoading(false));
      }
    } else {
      console.warn('Middleware: user_id is missing, skipping fetch.');
    }
  }

  return next(action);
};

export default onLogin;
