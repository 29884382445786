export const addHome = async (homeDetails) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found, please log in.');
      return null; // Indicate failure to retrieve token
    }
  
    try {
      // Make the API call to add the home
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/add_home`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(homeDetails),
      });
  
      // Check for HTTP errors
      if (!response.ok) {
        console.error(`HTTP Error: ${response.status} ${response.statusText}`);
        return null; // Indicate failure
      }
  
      // Parse and return the created home data from the response
      const result = await response.json();
      return result; // Return the created home details
    } catch (error) {
      console.error(`An error occurred: ${error.message}`);
      return null; // Indicate failure
    }
  };
  