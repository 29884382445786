import React, { useState, useEffect, useContext } from 'react';
import { ClientContext } from '../../ClientContext';
import './styling/Analysis.css';

import { useSelector, useDispatch } from 'react-redux';
import Disclosure from '../../components/Disclosure';

const Analysis = () => {
  const selectedClient = useSelector(state => state.client); // Access client slice
  const selectedClientId = selectedClient.selected_client_id;
  const selectedClientDetails = selectedClient.selected_client_details;
  const [analysis, setAnalysis] = useState({});
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchAnalysis = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('No token found, please log in.');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_analysis?client_id=${selectedClientId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const result = await response.json();
        if (response.ok) {
          setAnalysis(result);
        } else {
          setMessage(result.status);
        }
      } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
      }
    };

    if (selectedClientId) {
      fetchAnalysis();
    }
  }, [selectedClientId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAnalysis({
      ...analysis,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_analysis`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...analysis, client_id: selectedClientId })
      });

      const result = await response.json();
      if (response.ok) {
        setMessage('Analysis updated successfully.');
      } else {
        setMessage(result.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div>
    <div className="analysis-page">
      <h2>Analysis for {selectedClientDetails.first_name}</h2>
      {message && <p>{message}</p>}
      <form>
        <label>
          Property appreciation rate
          <input type="number" name="property_appreciation_rate" value={analysis.property_appreciation_rate || ''} onChange={handleChange} />
        </label>
        <label>
          Short-term analysis months
          <input type="number" name="short_term_analysis_months" value={analysis.short_term_analysis_months || ''} onChange={handleChange} />
        </label>
        <label>
          Long-term analysis years
          <input type="number" name="long_term_analysis_years" value={analysis.long_term_analysis_years || ''} onChange={handleChange} />
        </label>
        <label>
          Exclude reinvestments in monthly payment comparison
          <input type="checkbox" name="exclude_reinvestments" checked={analysis.exclude_reinvestments || false} onChange={handleChange} />
        </label>
        <label>
          Short-term chart type
          <select name="short_term_chart_type" value={analysis.short_term_chart_type || ''} onChange={handleChange}>
            <option value="Rent vs Principal Paid">Rent vs Principal Paid</option>
            <option value="Rent vs Tax Benefit">Rent vs Tax Benefit</option>
            <option value="Tax Benefit Analysis">Tax Benefit Analysis</option>
          </select>
        </label>
        <label>
          Long-term chart type
          <select name="long_term_chart_type" value={analysis.long_term_chart_type || ''} onChange={handleChange}>
            <option value="Total Principal Paid">Total Principal Paid</option>
            <option value="Total Net Worth">Total Net Worth</option>
            <option value="Total Interest and MI Paid">Total Interest and MI Paid</option>
          </select>
        </label>
      </form>
      <button onClick={handleSubmit}>Save Analysis</button>
    </div>
    <Disclosure></Disclosure>
    </div>
  );
};

export default Analysis;
