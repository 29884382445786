import React, { useState } from 'react';
import './Hero.css';
import { FaAnchorCircleCheck, FaPeopleGroup } from "react-icons/fa6";
import { FaBuilding } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'

function Hero({ openSignup }) {
  const [openFaq, setOpenFaq] = useState(null);
  const navigate = useNavigate();

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <div className="landing-hero">
      <div className="hero-content">
        <div className="hero-text">
          <h1>Assist in Your Mortgage Sales Process</h1>
          <p>We help loan officers streamline their sales process for mortgages, making it easier and more efficient.</p>
          <button onClick={openSignup} className="signup-button">Get Started</button>
        </div>
      </div>

      <div className="features">
        <div className="feature">
          <div className="feature-icon">
            <FaBuilding size={45} />
          </div>
          <h4>Streamline Sales</h4>
          <p>Utilize our tools to streamline the mortgage sales process, making it more efficient and effective.</p>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <FaPeopleGroup size={45} />
          </div>
          <h4>Connect with Clients</h4>
          <p>Enhance your client relationships and close more deals with our integrated communication tools.</p>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <FaAnchorCircleCheck size={45} />
          </div>
          <h4>Achieve Your Goals</h4>
          <p>Set and achieve your sales targets with the help of our powerful analytics and tracking features.</p>
        </div>
      </div>

      <div className="faq1-section">
        <h5>Frequently Asked Questions</h5>
        <div className="faq1-item" onClick={() => toggleFaq(0)}>
          <div className="faq1-question">
            <div className="faq1-q">
              <p>What is Pathfinder and how does it work?</p>
            </div>
            <span className={`faq1-arrow ${openFaq === 0 ? 'open' : ''}`}>&#9654;</span>
          </div>
          {openFaq === 0 && <p>Pathfinder is a platform designed to assist loan officers in the mortgage sales process, offering tools and features to streamline operations and improve client interactions.</p>}
        </div>
        <div className="faq1-item" onClick={() => toggleFaq(1)}>
          <div className="faq1-question">
            <div className="faq1-q">
              <p>How do I sign up for Pathfinder and what information is required?</p>
            </div>
            <span className={`faq1-arrow ${openFaq === 1 ? 'open' : ''}`}>&#9654;</span>
          </div>
          {openFaq === 1 && <p>Signing up for Pathfinder is simple! Just click the "Get Started" button and provide basic information to begin streamlining your mortgage sales process.</p>}
        </div>
        <div className="view-all-faqs">
          <a href="/faq" target="_blank" rel="noopener noreferrer" className="view-all-link">
            <h6>View All FAQs</h6>
          </a>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <p>Contact Us</p>
          <div className="socials">
            <a href="https://www.facebook.com/profile.php?id=61559164079527&mibextid=wwXIfr" target="_blank" rel="noopener noreferrer">Facebook</a>
            {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a> */}
            <a href="https://www.instagram.com/pathfinder.mc/#" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a href="https://pathfindermortgage.com/" target="_blank" rel="noopener noreferrer">Website</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Hero;
