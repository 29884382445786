import React from "react";
import { useSelector } from "react-redux";
import { ResponsiveBar } from "@nivo/bar";

const SupportRetirement = () => {
  // Select home and assumptions from Redux store
  const home = useSelector((state) => state.home.selected_home_details);
  const assumptions = useSelector((state) => state.clientAssumptions.selected_assumptions);

  if (!home || !assumptions) {
    return <p>Loading home data...</p>;
  }

  // Extract necessary values from Redux
  const { home_price, down_payment, interest_rate, term_months } = home;
  const { annual_rent_increase } = assumptions;

  const loanAmount = home_price - down_payment;
  const annualInterestRate = interest_rate / 100;
  const loanTermYears = term_months / 12;
  const rentIncreaseRate = annual_rent_increase / 100 || 0.03; // Default 3% rent increase

  // Function to calculate projected equity and rent over the years
  const calculateEquityRentData = () => {
    const data = [];
    let currentEquity = 0;
    let currentRent = home_price * 0.03; // Assume 3% initial rent of home price
  
    for (let year = 5; year <= 30; year += 5) {
      currentEquity = loanAmount * (year / loanTermYears); // Corrected equity calculation
      currentRent *= 1 + rentIncreaseRate; // Increase rent by the annual rate
  
      data.push({
        year: year.toString(),
        equity: Math.round(currentEquity),
        rent: Math.round(currentRent),
      });
    }
  
    return data;
  };
  

  const data = calculateEquityRentData();

  const customTheme = {
    axis: {
      ticks: {
        text: {
          fontSize: 14,
          fill: "#333",
        },
      },
      legend: {
        text: {
          fontSize: 16,
          fill: "#555",
        },
      },
    },
    legends: {
      text: {
        fontSize: 14,
        fill: "#333",
      },
    },
  };

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <h3 style={{ fontSize: "24px", color: "#333", marginBottom: "10px" }}>
        Support Retirement
      </h3>
      <p style={{ fontSize: "18px", color: "#555", maxWidth: "600px", margin: "0 auto 30px" }}>
        Equity calculation and rent appreciation based on an annual {rentIncreaseRate * 100} % increase.
      </p>

      <div style={{ height: "500px" }}>
      <ResponsiveBar
  data={data}
  keys={["equity", "rent"]}
  indexBy="year"
  margin={{ top: 50, right: 30, bottom: 50, left: 150 }}  // Keep adequate left margin
  padding={0.3}
  layout="vertical"
  groupMode="grouped"
  colors={{ scheme: "nivo" }}
  theme={{
    axis: {
      ticks: {
        text: {
          fontSize: 14,
          fill: "#333",
        },
      },
      legend: {
        text: {
          fontSize: 16,
          fill: "#555",
        },
      },
    },
    legends: {
      text: {
        fontSize: 14,
        fill: "#333",
      },
    },
  }}
  axisBottom={{
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Years",
    legendPosition: "middle",
    legendOffset: 40,
    format: value => value.toString(),  // Ensure years display properly
  }}
  axisLeft={{
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Amount ($)",
    legendPosition: "middle",  // Center label vertically
    legendOffset: -140,  // Adjust position to avoid overlap with tick labels
    format: value => `$${Number(value).toLocaleString()}`,  // Format values with commas and dollars
  }}
  enableLabel={false}
  borderRadius={5}
  enableGridX={true}
  enableGridY={true}
  legends={[
    {
      dataFrom: "keys",
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 90,  // Adjusted for layout
      itemWidth: 80,
      itemHeight: 20,
      itemsSpacing: 5,
      symbolSize: 18,
      itemDirection: "left-to-right",
    },
  ]}
  animate={true}
  motionStiffness={90}
  motionDamping={15}
/>

      </div>

      <div style={{ marginTop: "40px", maxWidth: "600px", margin: "0 auto", textAlign: "left" }}>
        <h4 style={{ fontSize: "22px", color: "#333", marginBottom: "10px" }}>
          Additional Information
        </h4>
        <p style={{ fontSize: "16px", color: "#555" }}>
          Understanding how your equity grows over time compared to rising rent costs helps you make informed financial decisions.
        </p>
      </div>
    </div>
  );
};

export default SupportRetirement;
