// utils/equationsHome.js

// Calculate Total Savings (Example: Rent vs Own)
export const calculateTotalSavings = (homePrice, downPayment) => {
    return homePrice - downPayment; // Placeholder formula
  };
  
  // Calculate Total Equity Gained
  export const calculateTotalEquityGained = (homePrice, loanAmount, annualAppreciationRate, annualInterestRate, yearsElapsed, loanTermYears) => {
    const monthlyInterestRate = annualInterestRate / 12; // Monthly interest rate
    const totalMonths = loanTermYears * 12; // Total number of payments
    const monthsElapsed = yearsElapsed * 12; // Convert years elapsed to months

    // Step 1: Calculate Remaining Mortgage Balance (Mx)
    const remainingMortgageBalance = loanAmount * (
        ((1 + monthlyInterestRate) ** totalMonths - (1 + monthlyInterestRate) ** monthsElapsed) /
        ((1 + monthlyInterestRate) ** totalMonths - 1)
    );

    // Step 2: Calculate Equity (Ex)
    const equity = (homePrice * (1 + annualAppreciationRate) ** yearsElapsed) - remainingMortgageBalance;

    return equity;
  };

  
  // Calculate Estimated Loan Interest
  export const calculateEstimatedLoanInterest = (loanAmount, interestRate, termMonths) => {
    return loanAmount * (interestRate / 100) * (termMonths / 12);
  };
  
  // Calculate Estimated Home Affordability
  export const calculateEstimatedHomeAffordability = (income, expenses) => {
    const affordabilityRate = 0.36; // 36% of income
    return income * affordabilityRate - expenses;
  };
  
  // Calculate Monthly Payment
  export const calculateMonthlyPayment = (loanAmount, interestRate, termMonths) => {
    const monthlyRate = interestRate / 100 / 12;
    return (
      (loanAmount * monthlyRate) /
      (1 - Math.pow(1 + monthlyRate, -termMonths))
    );
  };
  
  // Calculate Tax Write-Offs Per Year
  export const calculateTaxWriteOffsPerYear = (loanInterest, propertyTax) => {
    return loanInterest + propertyTax;
  };
  