import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contactInfo: {}, // All contact info by `client_id`
  selected_contact_details: {
    home_phone: '',
    cell_phone: '',
    fax: '',
    mailing_street: '',
    mailing_city: '',
    mailing_state: '',
    mailing_zip: '',
  },
};

export const clientContactSlice = createSlice({
  name: 'clientContact',
  initialState,
  reducers: {
    setContactInfo: (state, action) => {
      const contactsArray = action.payload;
      state.contactInfo = contactsArray.reduce((acc, contact) => {
        acc[contact.client_id] = contact;
        return acc;
      }, {});
    },
    updateSelectedContact: (state, action) => {
      const clientId = action.payload;
      if (state.contactInfo[clientId]) {
        state.selected_contact_details = { ...state.contactInfo[clientId] };
      }
    },
    addContactAndSelect: (state, action) => {
      const newContact = action.payload;
      state.contactInfo[newContact.client_id] = newContact; // Add new contact to contactInfo
      state.selected_contact_details = { ...newContact }; // Set as selected contact
    },
    updateHomePhone: (state, action) => {
      state.selected_contact_details.home_phone = action.payload;
    },
    updateCellPhone: (state, action) => {
      state.selected_contact_details.cell_phone = action.payload;
    },
    updateFax: (state, action) => {
      state.selected_contact_details.fax = action.payload;
    },
    updateMailingStreet: (state, action) => {
      state.selected_contact_details.mailing_street = action.payload;
    },
    updateMailingCity: (state, action) => {
      state.selected_contact_details.mailing_city = action.payload;
    },
    updateMailingState: (state, action) => {
      state.selected_contact_details.mailing_state = action.payload;
    },
    updateMailingZip: (state, action) => {
      state.selected_contact_details.mailing_zip = action.payload;
    },
    updateContactDetails: (state, action) => {
      state.selected_contact_details = { ...action.payload };
    },
    reset: () => initialState,
  },
});

export const {
  setContactInfo,
  updateSelectedContact,
  addContactAndSelect,
  updateHomePhone,
  updateCellPhone,
  updateFax,
  updateMailingStreet,
  updateMailingCity,
  updateMailingState,
  updateMailingZip,
  updateContactDetails,
  reset,
} = clientContactSlice.actions;

export default clientContactSlice.reducer;
