import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Form, Card } from "react-bootstrap";

const ReducePayment = () => {
  // Retrieve home data from Redux store
  const home = useSelector((state) => state.home.selected_home_details);

  // Destructuring values from Redux with default fallbacks
  const {
    home_price: homePrice = 200000, // Default home price
    down_payment: minDownPayment = 0, // Minimum down payment from Redux (default 0)
    interest_rate: interestRate = 5, // Default 5% interest rate
    term_months: loanTermMonths = 360, // Default 30 years (360 months)
  } = home || {};

  // State for selected down payment (initially min down payment)
  const [selectedDownPayment, setSelectedDownPayment] = useState(minDownPayment);

  // Calculate loan amount and monthly payment
  const calculateMonthlyPayment = (downPayment) => {
    const loanAmount = homePrice - downPayment;
    const monthlyInterestRate = (interestRate / 100) / 12;
    return (
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths))
    );
  };

  return (
    <Container fluid className="text-center py-5">
      <h3 style={{ fontWeight: "bold", marginBottom: "20px" }}>Reduce Monthly Payment</h3>
      <p style={{ fontSize: "18px" }}>Adjust your down payment to see its impact on your monthly mortgage payment.</p>

      <Form.Group controlId="downPaymentSlider" className="mt-4">
        <Form.Label style={{ fontSize: "18px", fontWeight: "bold" }}>
          Down Payment: ${selectedDownPayment.toLocaleString()} 
          ({((selectedDownPayment / homePrice) * 100).toFixed(0)}%)
        </Form.Label>
        <Form.Range
          min={0}
          max={homePrice}
          step={5000}
          value={selectedDownPayment}
          onChange={(e) => setSelectedDownPayment(parseInt(e.target.value))}
        />
      </Form.Group>

      <Row className="justify-content-center mt-4">
        <Col md={4}>
          <Card className="shadow-sm border border-secondary text-center">
            <Card.Body>
              <Card.Title style={{ fontWeight: "bold", fontSize: "20px" }}>Monthly Payment</Card.Title>
              <Card.Text style={{ fontSize: "24px", fontWeight: "bold", color: "#007bff" }}>
                ${Math.round(calculateMonthlyPayment(selectedDownPayment)).toLocaleString()}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="shadow-sm border border-secondary text-center">
            <Card.Body>
              <Card.Title style={{ fontWeight: "bold", fontSize: "20px" }}>Loan Amount</Card.Title>
              <Card.Text style={{ fontSize: "24px", fontWeight: "bold", color: "#F44336" }}>
                ${(homePrice - selectedDownPayment).toLocaleString()}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div style={{ marginTop: "30px", fontSize: "18px" }}>
        <h4>Impact Analysis</h4>
        <p>
          With a down payment of <strong>${selectedDownPayment.toLocaleString()}</strong>, your estimated monthly
          mortgage payment will be <strong>${Math.round(calculateMonthlyPayment(selectedDownPayment)).toLocaleString()}</strong>.
        </p>
      </div>
    </Container>
  );
};

export default ReducePayment;
