import React from 'react';
import PropTypes from 'prop-types';
import './MissingInfoAlert.css'; // Add styling for the alert component

const MissingInfoAlert = ({ missingFields }) => {
  if (!missingFields || missingFields.length === 0) {
    return null; // Don't render if there are no missing fields
  }

  return (
    <div className="alert">
      <p>
        <strong>Missing Information:</strong> Please provide the following before continuing:
      </p>
      <ul>
        {missingFields.map((field, index) => (
          <li key={index}>{field}</li>
        ))}
      </ul>
    </div>
  );
};

MissingInfoAlert.propTypes = {
  missingFields: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MissingInfoAlert;
