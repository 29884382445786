import React from 'react';
import { useSelector } from 'react-redux';
import './LoadingOverlay.css';

const LoadingOverlay = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  console.log('LoadingOverlay: isLoading =', isLoading);

  if (!isLoading) return null;

  return (
    <div className="loading-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingOverlay;
