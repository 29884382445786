import React from 'react';
import './ConfirmEmailChange.css'; // Reuse the same CSS for consistency

const ConfirmEmailChangePending = () => {
  return (
    <div className="confirm-email-container">
      <h1>Email Change Pending</h1>
      <p>We have sent confirmation emails to both your current and new email addresses.</p>
      <p>Please confirm the change from both email accounts to complete the process.</p>
      <p>If you didn’t receive the emails, check your spam folder or <a href="/">contact support</a>.</p>
      <a href="/" className="btn">Go to Home</a>
    </div>
  );
};

export default ConfirmEmailChangePending;
