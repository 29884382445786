import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TextField, Button, Container, Box, Typography, InputAdornment } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
//import './Modal.css';

const Signup = ({ onClose, openLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [signupError, setSignupError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const validatePassword = (password) => {
    const requirements = [
      { test: /.{8,}/, message: 'Password must be at least 8 characters long.' },
      { test: /[A-Z]/, message: 'Password must contain at least one uppercase letter.' },
      { test: /[a-z]/, message: 'Password must contain at least one lowercase letter.' },
      { test: /[0-9]/, message: 'Password must contain at least one digit.' },
      { test: /[!@#$%^&*(),.?":{}|<>]/, message: 'Password must contain at least one special character.' },
    ];

    for (const requirement of requirements) {
      if (!requirement.test.test(password)) {
        return requirement.message;
      }
    }
    return null;
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    setLoading(true);

    const passwordError = validatePassword(password);
    if (passwordError) {
      setSignupError(passwordError);
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setSignupError("Passwords don't match!");
      setLoading(false);
      return;
    }

    const signupData = { username, password, email };

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(signupData),
      });

      if (response.ok) {
        setSuccessMessage('Registration successful! Please check your email to verify your account.');
      } else {
        const text = await response.text();
        setSignupError(text);
      }
    } catch (error) {
      console.error('Error during signup:', error);
      setSignupError('An error occurred during signup.');
    } finally {
      setLoading(false);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content signup-form">
        {successMessage ? (
          <div>
            <p>{successMessage}</p>
            <Button variant="contained" color="primary" onClick={onClose}>
              Close
            </Button>
          </div>
        ) : (
          <form onSubmit={handleSignup}>
            <Container maxWidth="xs">
              <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Typography variant="h5">Sign Up</Typography>
              </Box>
              <TextField
                fullWidth
                label="Username"
                placeholder="Enter your username"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Email"
                placeholder="Enter your email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Password"
                type="password"
                placeholder="Enter your password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                placeholder="Confirm your password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                margin="normal"
              />
              {signupError && <Typography color="error" sx={{ mt: 1 }}>{signupError}</Typography>}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                disabled={loading}
              >
                {loading ? 'Signing Up...' : 'Sign Up'}
              </Button>
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Already have an account?{' '}
                <Link to="#" onClick={() => { onClose(); openLogin(); }}>
                  Login
                </Link>
              </Typography>
            </Container>
          </form>
        )}
      </div>
    </div>
  );
  
};

export default Signup;
