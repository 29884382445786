import React, { useState, useRef, useEffect } from 'react';
import { FaCommentDots, FaTimes, FaPaperPlane } from 'react-icons/fa';
import './Chatbot.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserContact } from '../../apiFetch/fetchUserContact';
import { setContactInfo } from '../../state/user/userContactSlice';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const chatbotRef = useRef(null);
  const dragHandleRef = useRef(null);
  const dispatch = useDispatch(); // Initialize the Redux dispatch function

  const clientId = useSelector((state) => state.client.selected_client_id);
  const userId = useSelector((state) => state.user.user_id);
  const contactInfo = useSelector((state) => state.userContact.selected_contact_details); // Get user contact info from Redux
  const client = useSelector((state) => state.client.selected_client_details);
  const home = useSelector((state) => state.home.selected_home_details);
  const [messages, setMessages] = useState([]);

  // Initialize chatbot messages when contactInfo is available
  useEffect(() => {
    if (contactInfo?.first_name) {
      setMessages([
        {
          sender: 'bot',
          text: `Hi ${contactInfo.first_name}! I'm Botimus Prime! How can I assist you today?`,
        },
      ]);
    }
  }, [contactInfo]);

  const [input, setInput] = useState('');
  const chatHistoryRef = useRef(null);

  const toggleChatbot = () => {
    setIsOpen((prev) => !prev);
  };


  useEffect(() => {
    // Ensure chat history scrolls to the bottom when messages are added
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (dragHandleRef.current && dragHandleRef.current.isDragging) {
        const chatbot = chatbotRef.current;
        const deltaX = dragHandleRef.current.startX - e.clientX;
        const deltaY = dragHandleRef.current.startY - e.clientY;

        chatbot.style.width = `${dragHandleRef.current.startWidth + deltaX}px`;
        chatbot.style.height = `${dragHandleRef.current.startHeight + deltaY}px`;
      }
    };

    const handleMouseUp = () => {
      if (dragHandleRef.current) {
        dragHandleRef.current.isDragging = false;
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const startDragging = (e) => {
    const chatbot = chatbotRef.current;
    dragHandleRef.current = {
      isDragging: true,
      startX: e.clientX,
      startY: e.clientY,
      startWidth: chatbot.offsetWidth,
      startHeight: chatbot.offsetHeight,
    };
  };

  const handleSendMessage = async (clientId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found, please log in.');
      return;
    }

    if (input.trim()) {
      setMessages((prevMessages) => [...prevMessages, { sender: 'user', text: input }]);
      try {
        // Construct the request payload
        const payload = {
          message: input,
          user_info: contactInfo || null, // Include user info if available
          client_info: client?.selected_client_id
            ? { ...client.selected_client_details } // Include selected client details if a client is selected
            : null,
          home_info: home?.selected_home_id > -1
            ? { ...home.selected_home_details } // Include selected home details if a home is selected
            : null,
        };

        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/chat`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload), // Send the full payload to the backend
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        // Parse the JSON response
        const data = await response.json();

        if (data.reply) {
          setMessages((prevMessages) => [...prevMessages, { sender: 'bot', text: data.reply }]);
        } else {
          console.error("Reply not found in response:", data);
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'bot', text: "Sorry, I couldn't process your request." }
          ]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: "Sorry, I couldn't process your request." }
        ]);
      }
      setInput('');
    }
  };

  return (
    <div className="chatbot">
      {isOpen ? (
        <div className="chatbot-window" ref={chatbotRef}>
          <div className="chatbot-header">
            <div
              className="drag-handle"
              onMouseDown={startDragging}
              title="Drag to resize"
            />
            <h3>Chatbot</h3>
            <FaTimes onClick={toggleChatbot} className="close-icon" />
          </div>
          <div className="chatbot-history" ref={chatHistoryRef}>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`message ${msg.sender === 'bot' ? 'bot' : 'user'}`}
              >
                {msg.text}
              </div>
            ))}
          </div>
          <div className="chatbot-input-area">
            <input
              type="text"
              placeholder="Type your message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <button onClick={() => handleSendMessage(clientId)} className="send-button">
              <FaPaperPlane />
            </button>
          </div>
        </div>
      ) : (
        <button className="chatbot-toggle" onClick={toggleChatbot}>
          <FaCommentDots />
        </button>
      )}
    </div>
  );
};

export default Chatbot;
