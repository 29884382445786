import React, { useEffect, useRef } from 'react';
import './styling/LoanInterestModal.css';
import { calculateInterestRate, getFICOAdjustment, getLTVAdjustment } from './PresentMode/utils/CreditUtils';

const InterestRateModal = ({ isOpen, onClose, loanDetails }) => {
    const modalRef = useRef();

  const { loanAmount, homeValue, ficoScore, loanTerm } = loanDetails || {};
  const baseRate = loanTerm === '30-year' ? 6.98 : 6.39;

  // Calculate adjustments and interest rate
  const ficoAdjustment = ficoScore ? getFICOAdjustment(ficoScore) : 'N/A';
  const ltvAdjustment = homeValue ? getLTVAdjustment(loanAmount, homeValue) : 'N/A';
  const calculatedRate = loanAmount > 0 && homeValue > 0 && ficoScore > 0
    ? calculateInterestRate({
        loanTerm,
        ficoScore,
        loanAmount,
        homeValue,
      }).toFixed(2)
    : 'N/A';

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, onClose]);

  if (!isOpen) return null;



  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <h3>Loan Interest Rate Details</h3>
        <div className="calculation-summary">
          <p><strong>Calculation Breakdown:</strong></p>
          <p>Base Rate: {baseRate}%</p>
          <p>FICO Adjustment: {ficoAdjustment}%</p>
          <p>LTV Adjustment: {ltvAdjustment}%</p>
          <p><strong>Calculated Interest Rate:</strong> {calculatedRate}%</p>
        </div>
        <h4>Adjustment Details</h4>
        <ul>
          <li>
            <strong>FICO Adjustment:</strong> Based on credit score ({ficoScore || 'N/A'}).
          </li>
          <li>
            <strong>LTV Adjustment:</strong> Based on loan-to-value ratio 
            (LTV = {loanAmount && homeValue ? ((loanAmount / homeValue) * 100).toFixed(2) : 'N/A'}%).
          </li>
          <li>
            <strong>Loan Term:</strong> {loanTerm || 'N/A'} (affects base rate).
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InterestRateModal;
