import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedHomes: [null, null], // Start with two homes for comparison
};

export const presentationCompareSlice = createSlice({
  name: "presentationCompare",
  initialState,
  reducers: {
    addHomeToCompare: (state) => {
      state.selectedHomes.push(null); // Add a new empty home slot
    },
    removeHomeFromCompare: (state, action) => {
      const index = action.payload;
      state.selectedHomes.splice(index, 1); // Remove the home at the specified index
    },
    updateHomeInCompare: (state, action) => {
      const { index, homeId } = action.payload;
      state.selectedHomes[index] = homeId; // Update the home at the specified index
    },
    resetCompareHomes: (state) => {
      state.selectedHomes = [null, null]; // Reset to the default two homes
    },
    reset: (state) => {
          Object.assign(state, initialState);
        },
  },
});

export const {
  addHomeToCompare,
  removeHomeFromCompare,
  updateHomeInCompare,
  resetCompareHomes,
  reset,
} = presentationCompareSlice.actions;

export default presentationCompareSlice.reducer;
