import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  expenditure: {}, // Store expenditure by client_id
  selected_client_id: null, // Track the currently selected client ID
  selected_expenditure: {
    maximum_mortgage_payment: null,
    ideal_mortgage_payment: null,
    price_range_low: null,
    price_range_high: null,
  },
};

const clientExpenditureSlice = createSlice({
  name: "clientExpenditure",
  initialState,
  reducers: {
    setExpenditure: (state, action) => {
      const expenditureArray = action.payload;
      state.expenditure = expenditureArray.reduce((acc, item) => {
        acc[item.client_id] = item;
        return acc;
      }, {});
    },

    updateSelectedExpenditure: (state, action) => {
      const clientId = action.payload;
      state.selected_client_id = clientId;

      if (state.expenditure[clientId]) {
        state.selected_expenditure = { ...state.expenditure[clientId] };
      } else {
        state.expenditure[clientId] = {
          maximum_mortgage_payment: null,
          ideal_mortgage_payment: null,
          price_range_low: null,
          price_range_high: null,
        };
        state.selected_expenditure = { ...state.expenditure[clientId] };
      }
    },

    updateSelectedExpenditureField: (state, action) => {
      const { field, value } = action.payload;

      // Update the selected_expenditure
      state.selected_expenditure[field] = value;

      // Persist changes to the global expenditure object using selected_client_id
      if (state.selected_client_id) {
        state.expenditure[state.selected_client_id] = {
          ...state.selected_expenditure,
        };
      } else {
        console.error("No selected_client_id to persist the state.");
      }

    },
    addExpenditureAndSelect: (state, action) => {
      const newExpenditure = action.payload;
      state.expenditure[newExpenditure.client_id] = newExpenditure;
      state.selected_expenditure = { ...newExpenditure };
    },

    reset: (state) => {
      state.expenditure = {};
      state.selected_client_id = null;
      state.selected_expenditure = {
        maximum_mortgage_payment: null,
        ideal_mortgage_payment: null,
        price_range_low: null,
        price_range_high: null,
      };
    },
  },
});

export const {
  setExpenditure,
  updateSelectedExpenditure,
  updateSelectedExpenditureField,
  addExpenditureAndSelect,
  reset,
} = clientExpenditureSlice.actions;

export default clientExpenditureSlice.reducer;
