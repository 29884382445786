import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ConfirmEmailChange = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading'); // loading, success, error

  useEffect(() => {
    const confirmEmailChange = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/confirm_email_change/${token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.redirected) {
          // Handle Flask redirection
          window.location.href = response.url;
        } else if (response.ok) {
          setStatus('success');
          navigate('/confirmEmailChangeSuccess');
        } else {
          setStatus('error');
          navigate('/confirmEmailChangeError');
        }
      } catch (err) {
        setStatus('error');
        navigate('/confirmEmailChangeError');
      }
    };

    confirmEmailChange();
  }, [token, navigate]);

  if (status === 'loading') return <p>Loading...</p>;
  return null;
};

export default ConfirmEmailChange;
