import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setClients,
  updateSelectedClient,
} from '../../../state/client/clientSlice';
import {
  setHomes,
  updateSelectedHome,
  updateSelectedHomeDetails,
  addHome as addHomeRedux,
  updateHome as updateHomeRedux,
} from '../../../state/home/homeSlice';
import { addHome } from '../../../utils/addHome';
import { updateHome } from '../../../utils/updateHome';
import { fetchHomes } from '../../../apiFetch/fetchHomes';
import { Dropdown } from 'primereact/dropdown';
import FormattedNumberInput from './onboardingClient/utils/FormattedNumberInput';
import './styling/AddHomePage.css';
import { calculateInterestRate } from './PresentMode/utils/CreditUtils';
import InterestRateModal from './LoanInterestModal';
import { deleteHome } from '../../../utils/deleteHome';

const AddHomePage = () => {
  const dispatch = useDispatch();

  // Access Redux state
  const clients = useSelector((state) => state.client.clients);
  const selectedClientId = useSelector((state) => state.client.selected_client_id);
  const affordability = useSelector(
    (state) => state.clientAffordability.selected_affordability_details
  );
  const homes = useSelector((state) =>
    Object.values(state.home.homes).filter((home) => home.client_id === selectedClientId)
  );
  const selectedHomeId = useSelector((state) => state.home.selected_home_id);
  const selectedHomeDetails = useSelector((state) => state.home.selected_home_details);

  const [isEditing, setIsEditing] = useState(false);
  const [isDownPaymentPercentage, setIsDownPaymentPercentage] = useState(false); // Default to dollar format

  const [isInterestRateModalOpen, setIsInterestRateModalOpen] = useState(false);

  const handleOpenInterestRateModal = () => setIsInterestRateModalOpen(true);
  const handleCloseInterestRateModal = () => setIsInterestRateModalOpen(false);

  const [baseTaxRate, setBaseTaxRate] = useState(1); // Default to 1
  const [additionalLocalTaxRate, setAdditionalLocalTaxRate] = useState(null); // Optional input


  const handleDownPaymentChange = (value) => {
    if (isDownPaymentPercentage) {
      const homePrice = parseFloat(selectedHomeDetails.home_price) || 0;
      const dollarAmount = (value / 100) * homePrice; // Convert percentage to dollar amount
      handleChange('down_payment', Math.round(dollarAmount));
    } else {
      handleChange('down_payment', value); // Store dollar amount directly
    }
  };

  useEffect(() => {
    if (selectedClientId) {
      fetchHomes(selectedClientId, (fetchedHomes) => {
        dispatch(setHomes(fetchedHomes)); // Populates `homes` in the Redux store
        console.log("fetched homes", fetchedHomes)
      });

    }
  }, [selectedClientId, dispatch]);

  useEffect(() => {
    const additionalRate = additionalLocalTaxRate || 0; // Default additional rate to 0 if null
    const calculatedPropertyTaxRate = baseTaxRate + additionalRate;
    handleChange("property_tax_rate", calculatedPropertyTaxRate.toFixed(2));
  }, [baseTaxRate, additionalLocalTaxRate]);

  useEffect(() => {
    console.log('Selected Home Details:', selectedHomeDetails);
  }, [selectedHomeDetails]);

  useEffect(() => {
    setIsEditing(selectedHomeId !== -1);
  }, [selectedHomeId]);

  useEffect(() => {
    const loanAmount = (selectedHomeDetails.home_price || 0) - (selectedHomeDetails.down_payment || 0);
    const homeValue = selectedHomeDetails.home_price || 0;
    const ficoScore = affordability.credit_status || 0;
    const loanTerm = selectedHomeDetails.term_months > 180 ? "30-year" : "15-year";
  
    if (loanAmount > 0 && homeValue > 0 && ficoScore > 0) {
      const calculatedRate = calculateInterestRate({
        loanTerm,
        ficoScore,
        loanAmount,
        homeValue,
        marketRiskPremium: 0, // Adjust this if necessary
        loanTermAdjustment: 0, // Adjust this if necessary
      });
  
      handleChange('interest_rate', calculatedRate.toFixed(2)); // Automatically update the interest rate field
    }
  }, [
    selectedHomeDetails.home_price,
    selectedHomeDetails.down_payment,
    selectedHomeDetails.fico_score,
    selectedHomeDetails.term_months,
  ]);
  
  // Handle form input changes
  const handleChange = (name, value) => {
    dispatch(updateSelectedHomeDetails({ ...selectedHomeDetails, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedClientId) {
      alert('Please select a client before adding or updating a home.');
      return;
    }

    const payload = {
      client_id: selectedClientId,
      address: selectedHomeDetails.address,
      type: 'N/A',
      home_price: parseFloat(selectedHomeDetails.home_price) || 0,
      down_payment: parseFloat(selectedHomeDetails.down_payment) || 0,
      interest_rate: parseFloat(selectedHomeDetails.interest_rate) || 0,
      property_tax_rate: parseFloat(selectedHomeDetails.property_tax_rate) || 0,
      home_insurance_fee: parseFloat(selectedHomeDetails.home_insurance_fee) || 0,
      pmi_rate: parseFloat(selectedHomeDetails.pmi_rate) || 0,
      hoa: parseFloat(selectedHomeDetails.hoa_fee) || 0,
      term_months: parseInt(selectedHomeDetails.term_months, 10) || 0,
      loan_type: selectedHomeDetails.loan_type || '',
      interest_only_months: parseInt(selectedHomeDetails.interest_only_months, 10) || 0,
      balloon_payment_months: parseInt(selectedHomeDetails.balloon_payment_months, 10) || 0,
    };

    try {
      let result;
      if (isEditing) {
        result = await updateHome(selectedHomeId, payload);
        if (result) {
          if (!selectedHomeId) {
            console.error('selectedHomeId is undefined');
            alert('No home is selected for editing.');
            return;
          }
          console.log('selectedHomeId is', selectedHomeId);
          dispatch(
            updateHomeRedux({
              ...payload,
              home_id: selectedHomeId, // Ensure the ID is preserved
            })
          );
          alert('Home updated successfully!');
        } else {
          alert('Failed to update home.');
        }
      } else {
        result = await addHome(payload);
        if (result) {
          dispatch(addHomeRedux(result));
          alert('Home added successfully!');
          fetchHomes(selectedClientId, (fetchedHomes) => {
            dispatch(setHomes(fetchedHomes)); // Update Redux store with new list
        
            console.log("Re-fetched homes after addition:", fetchedHomes);
        
            // Find the newly added home in the re-fetched homes
            const newlyAddedHome = fetchedHomes.find(
              (home) => home.home_id === result.home_id
            );
        
            if (newlyAddedHome) {
              // Dispatch updateSelectedHome AFTER setHomes to ensure proper state
              dispatch(updateSelectedHome(newlyAddedHome.home_id));
            }
          });
        } else {
          alert('Failed to add home.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  const handleDeleteHome = async () => {
    if (selectedHomeId === -1) return; // Do nothing if no home is selected
  
    const confirmation = window.confirm(
      "Are you sure you want to delete this home? This action cannot be undone."
    );
  
    if (!confirmation) return;
  
    try {
      const result = await deleteHome(selectedHomeId);
  
      if (result.success) {
        alert("Home deleted successfully!");
  
        // Refresh homes from the server and update Redux store
        fetchHomes(selectedClientId, (fetchedHomes) => {
          dispatch(setHomes(fetchedHomes));
  
          // Clear the selected home
          dispatch(updateSelectedHome(-1));
        });
      } else {
        alert(`Failed to delete home: ${result.message}`);
      }
    } catch (error) {
      console.error("Error deleting home:", error);
      alert("An unexpected error occurred while deleting the home.");
    }
  };
  
  

  return (
    <div className="add-home-page">
      <h1>{isEditing ? 'Update Home' : 'Add a New Home'}</h1>

      {/* Home Dropdown */}
      {!selectedClientId ? (
        <p>Please select a client to view homes.</p>
      ) : homes.length === 0 ? (
        <p>No homes available for the selected client.</p>
      ) : (
        <div className="form-group">
          <Dropdown
            value={selectedHomeId || null}
            options={homes.map((home) => ({
              label: home.address || 'Unknown Address',
              value: home.home_id,
            }))}
            onChange={(e) => {
              const selectedHome = homes.find((home) => home.home_id === e.value);
              if (selectedHome) {
                dispatch(updateSelectedHome(selectedHome.home_id));
              } else {
                dispatch(updateSelectedHome(-1));
              }
            }}
            placeholder="Select a Home"
            filter
            showClear
          />
        </div>
      )}


      <form onSubmit={handleSubmit}>
        <div>
          <label>Address:</label>
          <input
            type="text"
            value={selectedHomeDetails.address || ''}
            onChange={(e) => handleChange('address', e.target.value)}
          />
        </div>
        <div>
          <label>Home Price:</label>
          <FormattedNumberInput
            value={selectedHomeDetails.home_price || ''}
            onChange={(value) => handleChange('home_price', value)}
          />
        </div>


        <div className="down-payment-container">
  <label>Down Payment:</label>
  <div className="down-payment-wrapper">
    <div className="down-payment-toggle">
      <label>
        <input
          type="radio"
          name="downPaymentFormat"
          value="dollar"
          checked={!isDownPaymentPercentage}
          onChange={() => setIsDownPaymentPercentage(false)}
        />
        $
      </label>
      <label>
        <input
          type="radio"
          name="downPaymentFormat"
          value="percentage"
          checked={isDownPaymentPercentage}
          onChange={() => setIsDownPaymentPercentage(true)}
        />
        %
      </label>
    </div>
    <div className="down-payment-input-wrapper">
      <FormattedNumberInput
        value={
          isDownPaymentPercentage
            ? ((selectedHomeDetails.down_payment || 0) / (selectedHomeDetails.home_price || 1)) * 100
            : selectedHomeDetails.down_payment || ''
        }
        onChange={(value) => handleDownPaymentChange(value)}
        placeholder={isDownPaymentPercentage ? 'Enter percentage' : 'Enter dollar amount'}
      />
    </div>
  </div>
</div>
       
       
<div className="property-tax-container">
<div>
        <label>Base Property Tax Rate (%):</label>
        <input
          type="number"
          step="0.01"
          value={baseTaxRate}
          onChange={(e) =>
            setBaseTaxRate(e.target.value ? parseFloat(e.target.value) : 1)
          }
          placeholder="Enter base tax rate (e.g., 1.5)"
        />
      </div>
      <div>
        <label>Additional Local Property Tax Rate (%):</label>
        <input
          type="number"
          step="0.01"
          value={additionalLocalTaxRate !== null ? additionalLocalTaxRate : ""}
          onChange={(e) =>
            setAdditionalLocalTaxRate(
              e.target.value ? parseFloat(e.target.value) : null
            )
          }
          placeholder="Enter additional tax rate (if applicable)"
        />
      </div>
      <div>
        <label>Total Property Tax Rate (%):</label>
        <input
          type="text"
          readOnly
          value={(baseTaxRate + (additionalLocalTaxRate || 0)).toFixed(2)}
          placeholder="Calculated property tax rate"
        />
      </div>
    </div>



        <div>
          <label>Monthly HOA Fee:</label>
          <FormattedNumberInput
            value={selectedHomeDetails.hoa_fee || ''}
            onChange={(value) => handleChange('hoa_fee', value)}
          />
        </div>
        <div>
          <label>Loan Term (Months):</label>
          <FormattedNumberInput
            value={selectedHomeDetails.term_months || ''}
            onChange={(value) => handleChange('term_months', value)}
          />
        </div>


        <div>
  <label className="interest-rate-label">
    Estimated Loan Interest Rate (%):
    <span onClick={handleOpenInterestRateModal} className="info-icon">
      ℹ️
    </span>
  </label>
  <FormattedNumberInput
    value={selectedHomeDetails.interest_rate || ''}
    onChange={(value) => handleChange('interest_rate', value)}
  />
  <InterestRateModal
    isOpen={isInterestRateModalOpen}
    onClose={handleCloseInterestRateModal}
    loanDetails={{
      loanAmount: (selectedHomeDetails.home_price || 0) - (selectedHomeDetails.down_payment || 0),
      homeValue: selectedHomeDetails.home_price || 0,
      ficoScore: affordability?.credit_status || 0,
      loanTerm: selectedHomeDetails.term_months > 180 ? '30-year' : '15-year',
    }}
  />
</div>



        <div>
          <label>Loan Type: (ASK NICK)</label>
          <input
            type="text"
            value={selectedHomeDetails.loan_type || ''}
            onChange={(e) => handleChange('loan_type', e.target.value)}
          />
        </div>
        <div>
          <label>Interest-Only Months: (ASK NICK)</label>
          <FormattedNumberInput
            value={selectedHomeDetails.interest_only_months || ''}
            onChange={(value) => handleChange('interest_only_months', value)}
          />
        </div>
        <div>
          <label>Balloon Payment Months: (ASK NICK)</label>
          <FormattedNumberInput
            value={selectedHomeDetails.balloon_payment_months || ''}
            onChange={(value) => handleChange('balloon_payment_months', value)}
          />
        </div>
        <button type="submit">{isEditing ? 'Update Home' : 'Add Home'}</button>
      </form>
      <div>
      <button
        type="button"
        disabled={selectedHomeId === -1} // Disable button if no home is selected
        onClick={handleDeleteHome} // Call the handler function
      >
        Delete Home
      </button>
</div>

    </div>
  );
};

export default AddHomePage;
