import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Select, MenuItem, Card, Typography } from "@mui/material";
import {
  addHomeToCompare,
  removeHomeFromCompare,
  updateHomeInCompare,
} from "../../../../state/presentation/presentationCompareSlice";
import IndividualHome from "./IndividualHome";

const CompareMode = ({ homes, setCompareMode }) => {
  const dispatch = useDispatch();
  const selectedHomes = useSelector((state) => state.presentationCompare.selectedHomes);
  const clientGoals = useSelector((state) => state.clientGoals.goals);

  const handleAddHome = () => {
    if (selectedHomes.length < homes.length) {
      dispatch(addHomeToCompare());
    }
  };

  const handleRemoveHome = (index) => {
    dispatch(removeHomeFromCompare(index));
  };

  const handleSelectHome = (homeId, index) => {
    dispatch(updateHomeInCompare({ index, homeId }));
  };

  return (
    <div className="comparison-view" style={{ padding: "20px" }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setCompareMode(false)}
          >
            Exit Compare Mode
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddHome}
            disabled={selectedHomes.length >= homes.length}
          >
            + Add Home
          </Button>
        </Grid>
      </Grid>

      <div className="panes" style={{ marginTop: "20px" }}>
        {selectedHomes.map((homeId, index) => {
          const selectedHome = homes.find((home) => home.home_id === homeId);
          const homeGoals = selectedHome ? clientGoals[selectedHome.client_id] || {} : {};

          return (
            <Card key={index} variant="outlined" style={{ padding: "20px", margin: "10px" }}>
              <Typography variant="h5" align="center">
                Home {index + 1}
              </Typography>
              <Select
                value={homeId || ""}
                fullWidth
                displayEmpty
                onChange={(e) => handleSelectHome(parseInt(e.target.value, 10), index)}
              >
                <MenuItem value="" disabled>
                  Select Home
                </MenuItem>
                {homes
                  .filter((home) => !selectedHomes.includes(home.home_id) || home.home_id === homeId)
                  .map((home) => (
                    <MenuItem key={home.home_id} value={home.home_id}>
                      {home.address}
                    </MenuItem>
                  ))}
              </Select>
              {homeId && <IndividualHome home={selectedHome} goals={homeGoals} />}
              {selectedHomes.length > 2 && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleRemoveHome(index)}
                  style={{ marginTop: "10px" }}
                >
                  Remove Home
                </Button>
              )}
            </Card>
          );
        })}
      </div>
    </div>
  );
};

CompareMode.propTypes = {
  homes: PropTypes.arrayOf(
    PropTypes.shape({
      home_id: PropTypes.number.isRequired,
      client_id: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  setCompareMode: PropTypes.func.isRequired,
};

export default CompareMode;
