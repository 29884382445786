import React from 'react';

const Notifications = () => {
    return (
        <div>
            <h1>Notifications</h1>
        </div>
    );
};

export default Notifications;
