export const deleteHome = async (homeId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/delete_home/${homeId}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` },
      });
  
      if (response.ok) {
        return { success: true };
      } else {
        return { success: false, message: await response.text() };
      }
    } catch (error) {
      console.error('Error deleting home:', error);
      return { success: false, message: 'An error occurred' };
    }
  };
  