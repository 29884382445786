import {
  updateClientFirstName,
  updateClientLastName,
  updateClientMiddleName,
  updateClientDateOfBirth,
  updateClientReferredBy,
  updateClientEmail,
} from "../../../../../state/client/clientSlice";
import {
  updateHomePhone,
  updateCellPhone,
  updateFax,
  updateMailingStreet,
  updateMailingCity,
  updateMailingState,
  updateMailingZip,
  updateContactDetails,
} from "../../../../../state/client/clientContactSlice";
  import React, { useState, useEffect } from "react";
  import { useSelector, useDispatch } from "react-redux";
  import './ClientContactForm.css';
  import '../styling/FormsStyle.css';
  
  const ClientContactForm = () => {
    const dispatch = useDispatch();
  
    const selectedClient = useSelector((state) => state.client.selected_client_id);
    const clientDetails = useSelector(
      (state) => state.client.selected_client_details
    );
    const contactDetails = useSelector(
      (state) => state.clientContact.selected_contact_details
    );
  
    const [formErrors, setFormErrors] = useState({});
    const [hasChanged, setHasChanged] = useState(false);


    const validateForm = () => {
      const errors = {};
  
      // Client details validation
      if (!clientDetails.first_name) errors.first_name = "First Name is required.";
      if (!clientDetails.last_name) errors.last_name = "Last Name is required.";
      if (!clientDetails.middle_name) errors.middle_name = "Middle Name is required.";
      if (!clientDetails.date_of_birth)
        errors.date_of_birth = "Date of Birth is required.";
      if (!clientDetails.email) errors.email = "Email is required.";
      else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(clientDetails.email)) {
          errors.email = "Invalid email format.";
        }
      }
  
      // Contact details validation
      if (!contactDetails.cell_phone)
        errors.cell_phone = "Cell Phone is required.";
      if (!contactDetails.mailing_street)
        errors.mailing_street = "Mailing Street is required.";
      if (!contactDetails.mailing_city)
        errors.mailing_city = "Mailing City is required.";
      if (!contactDetails.mailing_state)
        errors.mailing_state = "Mailing State is required.";
      if (!contactDetails.mailing_zip)
        errors.mailing_zip = "Mailing Zip is required.";
  
      // Client ID validation
      if (!selectedClient) errors.client_id = "Client ID is missing.";
  
      setFormErrors(errors);
  
      return Object.keys(errors).length === 0;
    };
  
    const saveClient = async (clientPayload) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/clients`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(clientPayload),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Client saved successfully:", result);
        return result.id; // Return the saved client ID for linking contacts
      } else {
        const error = await response.json();
        console.error("Failed to save client:", error);
        throw new Error(error.status);
      }
    } catch (error) {
      console.error("Error saving client:", error.message);
    }
  };

  const saveClientContact = async (contactPayload) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/client_contact`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(contactPayload),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Contact saved successfully:", result);
      } else {
        const error = await response.json();
        console.error("Failed to save contact:", error);
        throw new Error(error.status);
      }
    } catch (error) {
      console.error("Error saving contact:", error.message);
    }
  };
    const handleSaveChanges = async () => {
      if (!validateForm()) {
        return; // Exit if validation fails
      }
  
      try {
        const clientPayload = {
          client_id: selectedClient,
          first_name: clientDetails.first_name,
          last_name: clientDetails.last_name,
          middle_name: clientDetails.middle_name,
          dob: clientDetails.date_of_birth,
          referred_by: clientDetails.referred_by || "N/A", // Optional field
          email: clientDetails.email,
        };
  
        const contactPayload = {
          client_id: selectedClient,
          home_phone: contactDetails.home_phone, // Optional field
          cell_phone: contactDetails.cell_phone,
          fax: contactDetails.fax,
          mailing_street: contactDetails.mailing_street,
          mailing_city: contactDetails.mailing_city,
          mailing_state: contactDetails.mailing_state,
          mailing_zip: contactDetails.mailing_zip,
        };
  
        // Assume saveClient and saveClientContact are defined
        await saveClient(clientPayload);
        await saveClientContact(contactPayload);
  
        alert("Changes saved successfully!");
      } catch (error) {
        console.error("Error saving changes:", error.message);
      }
    };

    const handleInputChange = (field, value, slice) => {
      setHasChanged(true);
      if (slice === "client") {
        switch (field) {
          case "first_name":
            dispatch(updateClientFirstName(value));
            break;
          case "last_name":
            dispatch(updateClientLastName(value));
            break;
          case "middle_name":
            dispatch(updateClientMiddleName(value));
            break;
          case "date_of_birth":
            dispatch(updateClientDateOfBirth(value));
            break;
          case "referred_by":
            dispatch(updateClientReferredBy(value));
            break;
          case "email":
            dispatch(updateClientEmail(value));
            break;
          default:
            break;
        }
      } else if (slice === "contact") {
        switch (field) {
          case "home_phone":
            dispatch(updateHomePhone(value));
            break;
          case "cell_phone":
            dispatch(updateCellPhone(value));
            break;
          case "fax":
            dispatch(updateFax(value));
            break;
          case "mailing_street":
            dispatch(updateMailingStreet(value));
            break;
          case "mailing_city":
            dispatch(updateMailingCity(value));
            break;
          case "mailing_state":
            dispatch(updateMailingState(value));
            break;
          case "mailing_zip":
            dispatch(updateMailingZip(value));
            break;
          default:
            break;
        }
      }
    };
  
    const renderError = (field) => {
      return formErrors[field] ? (
        <div className="error-message">{formErrors[field]}</div>
      ) : null;
    };
  
return (
  <div className="client-info-form">
    <h2>Contact Information</h2>
    <form>
      {/* Personal Information Section */}
      <fieldset>
        <legend>Personal Information</legend>
        <label>
          First Name:
          <input
            type="text"
            value={clientDetails.first_name || ""}
            onChange={(e) =>
              handleInputChange("first_name", e.target.value, "client")
            }
            className={formErrors.first_name ? "error-input" : ""}
          />
          {renderError("first_name")}
        </label>
        <label>
          Last Name:
          <input
            type="text"
            value={clientDetails.last_name || ""}
            onChange={(e) =>
              handleInputChange("last_name", e.target.value, "client")
            }
            className={formErrors.last_name ? "error-input" : ""}
          />
          {renderError("last_name")}
        </label>
        <label>
          Middle Name:
          <input
            type="text"
            value={clientDetails.middle_name || ""}
            onChange={(e) =>
              handleInputChange("middle_name", e.target.value, "client")
            }
            className={formErrors.middle_name ? "error-input" : ""}
          />
          {renderError("middle_name")}
        </label>
        <label>
          Date of Birth:
          <input
            type="date"
            value={clientDetails.date_of_birth || ""}
            onChange={(e) =>
              handleInputChange("date_of_birth", e.target.value, "client")
            }
            className={formErrors.date_of_birth ? "error-input" : ""}
          />
          {renderError("date_of_birth")}
        </label>
        <label>
          Referred By:
          <input
            type="text"
            value={clientDetails.referred_by || ""}
            onChange={(e) =>
              handleInputChange("referred_by", e.target.value, "client")
            }
          />
        </label>
      </fieldset>

      {/* Contact Information Section */}
      <fieldset>
        <legend>Contact Information</legend>
        <label>
          Email:
          <input
            type="email"
            value={clientDetails.email || ""}
            onChange={(e) =>
              handleInputChange("email", e.target.value, "client")
            }
            className={formErrors.email ? "error-input" : ""}
          />
          {renderError("email")}
        </label>
        <label>
          Cell Phone:
          <input
            type="tel"
            value={contactDetails.cell_phone || ""}
            onChange={(e) =>
              handleInputChange("cell_phone", e.target.value, "contact")
            }
            className={formErrors.cell_phone ? "error-input" : ""}
          />
          {renderError("cell_phone")}
        </label>
        <label>
          Home Phone:
          <input
            type="tel"
            value={contactDetails.home_phone || ""}
            onChange={(e) =>
              handleInputChange("home_phone", e.target.value, "contact")
            }
          />
        </label>
      </fieldset>

      {/* Mailing Address Section */}
      <fieldset>
        <legend>Mailing Address</legend>
        <label>
          Mailing Street:
          <input
            type="text"
            value={contactDetails.mailing_street || ""}
            onChange={(e) =>
              handleInputChange("mailing_street", e.target.value, "contact")
            }
            className={formErrors.mailing_street ? "error-input" : ""}
          />
          {renderError("mailing_street")}
        </label>
        <label>
          Mailing City:
          <input
            type="text"
            value={contactDetails.mailing_city || ""}
            onChange={(e) =>
              handleInputChange("mailing_city", e.target.value, "contact")
            }
            className={formErrors.mailing_city ? "error-input" : ""}
          />
          {renderError("mailing_city")}
        </label>
        <label>
          Mailing State:
          <input
            type="text"
            value={contactDetails.mailing_state || ""}
            onChange={(e) =>
              handleInputChange("mailing_state", e.target.value, "contact")
            }
            className={formErrors.mailing_state ? "error-input" : ""}
          />
          {renderError("mailing_state")}
        </label>
        <label>
          Mailing Zip:
          <input
            type="text"
            value={contactDetails.mailing_zip || ""}
            onChange={(e) =>
              handleInputChange("mailing_zip", e.target.value, "contact")
            }
            className={formErrors.mailing_zip ? "error-input" : ""}
          />
          {renderError("mailing_zip")}
        </label>
      </fieldset>

      {/* Save Button */}
      <button
        className="save-button"
        type="button"
        onClick={handleSaveChanges}
      >
        Save Changes
      </button>
    </form>
  </div>
);
  }
  
  export default ClientContactForm;
  