export const saveClientInfo = async (clientPayload) => {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/client_info`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(clientPayload),
      }
    );

    if (response.ok) {
      const result = await response.json();
      console.log("Client information saved successfully:", result);
      return result;
    } else {
      const error = await response.json();
      console.error("Failed to save client information:", error);
      throw new Error(error.message || "Unknown error occurred");
    }
  } catch (error) {
    console.error("Error saving client information:", error.message);
    throw error;
  }
};
