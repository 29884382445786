import React from "react";

const FormattedNumberInput = ({ value, onChange, placeholder = "" }) => {
  // Formatter for numbers with commas and decimals
  const formatNumber = (value) => {
    if (!value) return "";
    const stringValue = value.toString(); // Ensure value is treated as a string
    const parts = stringValue.split("."); // Split integer and decimal parts
    const formattedInteger = new Intl.NumberFormat("en-US").format(parts[0].replace(/,/g, ""));
    return parts.length > 1 ? `${formattedInteger}.${parts[1]}` : formattedInteger; // Preserve decimals
  };

  const handleInputChange = (e) => {
    const rawValue = e.target.value.replace(/,/g, ""); // Remove commas
    if (/^\d*\.?\d*$/.test(rawValue)) { // Allow only valid numbers with optional decimals
      onChange(rawValue); // Pass raw value to the parent component
    }
  };

  return (
    <input
      type="text"
      value={formatNumber(value)}
      onChange={handleInputChange}
      placeholder={placeholder}
    />
  );
};

export default FormattedNumberInput;
