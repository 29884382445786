import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  affordability: {}, // All affordability info by `client_id`
  selected_client_id: null, // Track the currently selected client ID
  selected_affordability_details: {
    id: null,
    client_id: null,
    current_annual_gross_income: null,
    credit_status: '',
    savings_balance: null,
    savings_interest_rate: null,
    standard_deduction: null,
    tax_bracket: null,
    filing_status: '',
    annual_mcc_tax_credit: 0,
    maximum_mcc_tax_credit: 0,
  },
};

export const clientAffordabilitySlice = createSlice({
  name: 'clientAffordability',
  initialState,
  reducers: {
    setAffordability: (state, action) => {
      const affordabilityArray = action.payload;
      state.affordability = affordabilityArray.reduce((acc, item) => {
        acc[item.client_id] = item;
        return acc;
      }, {});
    },

    updateSelectedAffordability: (state, action) => {
      const clientId = action.payload;
      state.selected_client_id = clientId;

      if (state.affordability[clientId]) {
        // Update selected affordability details based on the global state
        state.selected_affordability_details = {
          ...state.affordability[clientId],
          client_id: clientId,
        };
      } else {
        // Initialize a default state for the selected client if not found
        state.affordability[clientId] = {
          id: null,
          client_id: clientId,
          current_annual_gross_income: null,
          credit_status: '',
          savings_balance: null,
          savings_interest_rate: null,
          standard_deduction: null,
          tax_bracket: null,
          filing_status: '',
          annual_mcc_tax_credit: 0,
          maximum_mcc_tax_credit: 0,
        };
        state.selected_affordability_details = { ...state.affordability[clientId] };
      }
    },

    addAffordabilityAndSelect: (state, action) => {
      const newAffordability = action.payload;
      state.affordability[newAffordability.client_id] = newAffordability;
      state.selected_affordability_details = { ...newAffordability };
      state.selected_client_id = newAffordability.client_id;
    },

    updateCurrentAnnualGrossIncome: (state, action) => {
      state.selected_affordability_details.current_annual_gross_income = action.payload;

      if (state.selected_client_id) {
        state.affordability[state.selected_client_id].current_annual_gross_income = action.payload;
      }
    },

    updateCreditStatus: (state, action) => {
      state.selected_affordability_details.credit_status = action.payload;

      if (state.selected_client_id) {
        state.affordability[state.selected_client_id].credit_status = action.payload;
      }
    },

    updateSavingsBalance: (state, action) => {
      state.selected_affordability_details.savings_balance = action.payload;

      if (state.selected_client_id) {
        state.affordability[state.selected_client_id].savings_balance = action.payload;
      }
    },

    updateSavingsInterestRate: (state, action) => {
      state.selected_affordability_details.savings_interest_rate = action.payload;

      if (state.selected_client_id) {
        state.affordability[state.selected_client_id].savings_interest_rate = action.payload;
      }
    },

    updateStandardDeduction: (state, action) => {
      state.selected_affordability_details.standard_deduction = action.payload;

      if (state.selected_client_id) {
        state.affordability[state.selected_client_id].standard_deduction = action.payload;
      }
    },

    updateTaxBracket: (state, action) => {
      state.selected_affordability_details.tax_bracket = action.payload;

      if (state.selected_client_id) {
        state.affordability[state.selected_client_id].tax_bracket = action.payload;
      }
    },

    updateFilingStatus: (state, action) => {
      state.selected_affordability_details.filing_status = action.payload;

      if (state.selected_client_id) {
        state.affordability[state.selected_client_id].filing_status = action.payload;
      }
    },

    updateAnnualMcCTaxCredit: (state, action) => {
      state.selected_affordability_details.annual_mcc_tax_credit = action.payload;

      if (state.selected_client_id) {
        state.affordability[state.selected_client_id].annual_mcc_tax_credit = action.payload;
      }
    },

    updateMaximumMcCTaxCredit: (state, action) => {
      state.selected_affordability_details.maximum_mcc_tax_credit = action.payload;

      if (state.selected_client_id) {
        state.affordability[state.selected_client_id].maximum_mcc_tax_credit = action.payload;
      }
    },

    reset: () => initialState,
  },
});

export const {
  setAffordability,
  updateSelectedAffordability,
  addAffordabilityAndSelect,
  updateCurrentAnnualGrossIncome,
  updateCreditStatus,
  updateSavingsBalance,
  updateSavingsInterestRate,
  updateStandardDeduction,
  updateTaxBracket,
  updateFilingStatus,
  updateAnnualMcCTaxCredit,
  updateMaximumMcCTaxCredit,
  reset,
} = clientAffordabilitySlice.actions;

export default clientAffordabilitySlice.reducer;
