import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown"; // PrimeReact Dropdown
import ClientContactForm from "./components/ClientContactForm";
import AffordabilityForm from "./components/AffordabilityAndExpenditureForm";
import AssumptionsForm from "./components/AssumptionsForm";
import GoalsForm from "./components/GoalsForm";
import ClientInfoForm from "./components/ClientInfoForm"; // Import the ClientInfoForm

import { fetchClients } from "../../../../apiFetch/fetchClients";
import { setClients, updateSelectedClient } from "../../../../state/client/clientSlice";
import "./styling/EditClient.css";
import Disclosure from "../../../components/Disclosure";

const EditClient = () => {
  const dispatch = useDispatch();

  // Redux State
  const clients = useSelector((state) => state.client.clients);
  const selectedClient = useSelector((state) => state.client.selected_client_id);

  // Local State
  const [activeTab, setActiveTab] = useState("clientContact");

  // Array of tabs for easier navigation
  const tabs = [
    { id: "clientContact", label: "Contact Information" },
    { id: "clientInfo", label: "Client Information" },
    { id: "assumptions", label: "Buyer Status & Rental Info" },
    { id: "goals", label: "Goals" },
    { id: "affordability", label: "Affordability & Expenditure" },
  ];

  // Fetch Clients on Component Mount
  useEffect(() => {
    fetchClients((fetchedClients) => {
      dispatch(setClients(fetchedClients));
    });
  }, [dispatch]);

  // Dynamic Save Logic for Each Tab
  const handleSave = () => {
    if (!selectedClient) return;

    switch (activeTab) {
      case "clientContact":
        console.log("Saving Contact Information...");
        // Call save logic for Contact Information
        break;
      case "clientInfo":
        console.log("Saving Client Information...");
        // Call save logic for Client Information
        break;
      case "assumptions":
        console.log("Saving Assumptions...");
        // Call save logic for Assumptions
        break;
      case "goals":
        console.log("Saving Goals...");
        // Call save logic for Goals
        break;
      case "affordability":
        console.log("Saving Affordability...");
        // Call save logic for Affordability
        break;
      default:
        console.log("No save logic available for this tab.");
    }
  };

  // Render the currently active tab
  const renderTabContent = () => {
    if (!selectedClient) {
      return <p>Please select a client to start editing.</p>;
    }

    switch (activeTab) {
      case "clientInfo":
        return <ClientInfoForm />;
      case "clientContact":
        return <ClientContactForm />;
      case "assumptions":
        return <AssumptionsForm />;
      case "goals":
        return <GoalsForm />;
      case "affordability":
        return <AffordabilityForm />;
      default:
        return <ClientInfoForm />;
    }
  };

  // Navigate to the next or previous tab
  const handleNavigation = (direction) => {
    const currentIndex = tabs.findIndex((tab) => tab.id === activeTab);
    const newIndex = currentIndex + direction;

    if (newIndex >= 0 && newIndex < tabs.length) {
      setActiveTab(tabs[newIndex].id);
    }
  };

  return (
    <div>
      <div className="edit-client-header-container">
        <h1 className="edit-client-header">Manage Client Information</h1>
        {/* <div className="client-selection-dropdown">
          <Dropdown
            value={selectedClient}
            options={Object.values(clients).map((client) => ({
              label: `${client.first_name} ${client.last_name}`,
              value: client.client_id,
            }))}
            onChange={(e) => {
              dispatch(updateSelectedClient(e.value));
            }}
            placeholder="Select a Client"
            filter // Enable search functionality
          />
        </div> */}
      </div>

      {/* Tab Navigation */}
      <div className="edit-client-tabs">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`tab-button ${activeTab === tab.id ? "active" : ""}`}
            onClick={() => setActiveTab(tab.id)}
            disabled={!selectedClient}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="tab-content">{renderTabContent()}</div>

      {/* Unified Button Container */}
      <div className="edit-client-buttons">
        <button
          onClick={() => handleNavigation(-1)}
          disabled={tabs.findIndex((tab) => tab.id === activeTab) === 0 || !selectedClient}
          className="nav-button back-button"
        >
          Back
        </button>

        {/* <button
          onClick={handleSave}
          disabled={!selectedClient}
          className="nav-button save-button"
        >
          Save
        </button> */}

        <button
          onClick={() => handleNavigation(1)}
          disabled={
            tabs.findIndex((tab) => tab.id === activeTab) === tabs.length - 1 || !selectedClient
          }
          className="nav-button next-button"
        >
          Next
        </button>
      </div>
      <Disclosure />
    </div>
  );
};

export default EditClient;
