// src/apiFetch/fetchClientGoals.js
export const fetchClientGoals = async (clientId, setGoalsCallback) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found, please log in.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_client_goals/${clientId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      const result = await response.json();
  
      if (response.ok) {
        console.log('Fetched Client Goals:', result);
        setGoalsCallback(result); // Pass the fetched goals to the callback
      } else {
        console.warn(`Error fetching client goals: ${result.status}`);
        setGoalsCallback(null); // Pass null if no goals found
      }
    } catch (error) {
      console.error(`An error occurred while fetching client goals: ${error.message}`);
      setGoalsCallback(null); // Pass null if an error occurs
    }
  };
  