import React from 'react';
import './ConfirmEmailChange.css';

const ConfirmEmailChangeError = () => {
  return (
    <div className="confirm-email-container">
      <h1>Error</h1>
      <p>Invalid or expired token. Please try again.</p>
      <a href="/" className="btn">Go to Home</a>
    </div>
  );
};

export default ConfirmEmailChangeError;
