import React, { useState } from 'react';
import { TextField, Button, Container, Box, Typography, InputAdornment } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const forgotPassword = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/forgot_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      setMessage(data.msg);
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred while trying to reset the password. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <Container maxWidth="xs">
          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Typography variant="h5">Forgot Password</Typography>
          </Box>
          <Typography variant="body2" align="center" sx={{ mb: 3 }}>
            Please enter your email address. You will receive a link to create a new password.
          </Typography>
          <TextField
            fullWidth
            label="Email Address"
            placeholder="Enter your email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
          />
          {message && (
            <Typography
              variant="body2"
              color={message.toLowerCase().includes('error') ? 'error' : 'primary'}
              sx={{ mt: 1 }}
            >
              {message}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={forgotPassword}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Reset Password'}
          </Button>
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            <Link to="/">Back to Login</Link>
          </Typography>
        </Container>
      </div>
    </div>
  );
};

export default ForgotPassword;
