import { configureStore } from '@reduxjs/toolkit';
import homeReducer from './home/homeSlice';
import userReducer from './user/userSlice';
import userContactReducer from './user/userContactSlice';

import clientsReducer from './client/clientSlice';
import clientContactReducer from './client/clientContactSlice';
import clientAffordabilityReducer from './client/clientAffordabilitySlice';
import clientInfoReducer from './client/clientInfoSlice';
import clientGoalsReducer from './client/clientGoalsSlice';
import clientExpenditureReducer from './client/clientExpenditureSlice';
import clientAssumptionsReducer from './client/clientAssumptionsSlice';

import loadingOverlayReducer from './loading/loadingSlice';

import presentationCompareReducer from './presentation/presentationCompareSlice';

// import rentReducer from './calculations/rentSlice'

import clientMiddleware from '../middleware/onUpdateSelectedClient';
import homeMiddleware from '../middleware/onUpdateClientIDUpdateHomes';
import userContactMiddleware from '../middleware/onUpdateUserContact';
import clientMiddlewareRest from '../middleware/onUpdateSelectedClientRest';
import onLogin from '../middleware/onLogin'; 


export const store = configureStore({
  reducer: {
    user: userReducer,
    userContact: userContactReducer,
    home: homeReducer,
    client: clientsReducer,
    clientContact: clientContactReducer,
    clientAffordability: clientAffordabilityReducer,
    clientGoals: clientGoalsReducer,
    clientInfo: clientInfoReducer,
    clientAssumptions: clientAssumptionsReducer,
    clientExpenditure: clientExpenditureReducer,
    loading: loadingOverlayReducer,
    presentationCompare: presentationCompareReducer,
    // rent: rentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      clientMiddleware,
      homeMiddleware,
      userContactMiddleware,
      onLogin,
      clientMiddlewareRest
    ),
});

export default store;
