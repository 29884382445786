import { setLoading } from '../state/loading/loadingSlice';
import { updateSelectedClient } from '../state/client/clientSlice';

import { fetchClientInfo } from '../apiFetch/fetchClientInfo';
import { fetchClientContact } from '../apiFetch/fetchClientContact';
import { fetchClientGoals } from '../apiFetch/fetchClientGoals';
import { fetchClientExpenditure } from '../apiFetch/fetchClientExpenditure';
import { fetchClientAssumptions } from '../apiFetch/fetchClientAssumptions';
import { fetchClientAffordability } from '../apiFetch/fetchClientAffordability';

import { addClientInfoAndSelect } from '../state/client/clientInfoSlice';
import { addContactAndSelect } from '../state/client/clientContactSlice';
import { addGoalsAndSelect } from '../state/client/clientGoalsSlice';
import { addExpenditureAndSelect } from '../state/client/clientExpenditureSlice';
import { addAssumptionsAndSelect } from '../state/client/clientAssumptionsSlice';
import { addAffordabilityAndSelect } from '../state/client/clientAffordabilitySlice';

const clientMiddleware = (store) => (next) => async (action) => {
  if (action.type === updateSelectedClient.type) {
    const clientId = action.payload;

    if (clientId) {
      try {
        // Start loading
        store.dispatch(setLoading(true));
        

        // Fetch client info
        await fetchClientInfo(clientId, (clientInfo) => {
          if (clientInfo) {
            store.dispatch(addClientInfoAndSelect(clientInfo));
          }
        });
        store.dispatch(setLoading(false));
        // Fetch client contact
        // await fetchClientContact(clientId, (clientContact) => {
        //   if (clientContact) {
        //     store.dispatch(addContactAndSelect(clientContact));
        //   }
        // });

        // // Fetch client goals
        // await fetchClientGoals(clientId, (clientGoals) => {
        //   if (clientGoals) {
        //     store.dispatch(addGoalsAndSelect(clientGoals));
        //   }
        // });

        // // Fetch client expenditure
        // await fetchClientExpenditure(clientId, (clientExpenditure) => {
        //   if (clientExpenditure) {
        //     store.dispatch(addExpenditureAndSelect(clientExpenditure));
        //   }
        // });

        // // Fetch client assumptions
        // await fetchClientAssumptions(clientId, (clientAssumptions) => {
        //   if (clientAssumptions) {
        //     store.dispatch(addAssumptionsAndSelect(clientAssumptions));
        //   }
        // });

        // // Fetch client affordability
        // await fetchClientAffordability(clientId, (clientAffordability) => {
        //   if (clientAffordability) {
        //     store.dispatch(addAffordabilityAndSelect(clientAffordability));
        //   }
        // });
      } catch (error) {
        console.error('Middleware: Error fetching client data:', error);
      } finally {
        // Stop loading

      }
    }
  }

  return next(action);
};

export default clientMiddleware;
