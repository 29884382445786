// src/utils/fetchClientAffordability.js
export const fetchClientAffordability = async (clientId, setClientAffordability) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found, please log in.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_client_affordability/${clientId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
  
      const result = await response.json();
      if (response.ok) {
        console.log('Fetched Client Affordability:', result); // Log fetched affordability details
        setClientAffordability(result);
      } else {
        console.log(result.status);
      }
    } catch (error) {
      console.log(`An error occurred: ${error.message}`);
    }
  };
  