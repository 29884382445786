import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { handleSignOut } from './handleSignOut';
import './SettingsLayout.css';

const SettingsLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchCurrentUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/current_user`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const userData = await response.json();
      if (response.ok) {
        setCurrentUser(userData.email);
      } else {
        navigate('/');
      }
    } catch (error) {
      navigate('/');
    }
  };

  const navItems = [
    { text: 'Back', to: '/HeaderLayout/Clients', icon: 'pi pi-arrow-left' },
    { text: 'Contact', to: '/SettingsLayout/contact', icon: 'pi pi-user' },
    { text: 'Account', to: '/SettingsLayout/account', icon: 'pi pi-cog' },
    { text: 'Help and Feedback', to: '/SettingsLayout/help', icon: 'pi pi-question-circle' },
  ];

  return (
    <div className="layout-container">
      <header className="header d-flex justify-content-between align-items-center">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + "/assets/pf.png"} alt="logo" className="logo-text" />
        </div>
        <div className="user-info d-flex align-items-center">
          <Dropdown>
          <Dropdown.Toggle
            className="custom-dropdown-toggle"
            id="dropdown-basic"
          >
            <i className="pi pi-user mr-2"></i>
            {currentUser || 'Account'}
          </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleSignOut(dispatch, navigate)}>
                <i className="pi pi-sign-out mr-2"></i> Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <div className="sidebar">
        <ul className="navbar-nav flex-column">
          {navItems.map((item, index) => (
            <li className="nav-item" key={index}>
              <NavLink
                to={item.to}
                className="nav-link"
              >
                <i className={item.icon + " mr-2"}></i>
                <span>{item.text}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      <main className="main-content">
        <Outlet />
      </main>
    </div>
  );
};

export default SettingsLayout;
