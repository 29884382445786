import React, { useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Dropdown, Card, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

const PurchaseNewHome = () => {
  const [selectedYear, setSelectedYear] = useState(5);

  // Retrieve home data from Redux
  const home = useSelector((state) => state.home.selected_home_details);

  // If home data is not available, show a loading message
  if (!home || home.client_id === -1) {
    return <p>Loading home details...</p>;
  }

  // Destructuring values from Redux with default fallbacks
  const {
    home_price: homePrice = 0,
    down_payment: downPayment = 0,
    interest_rate: interestRate = 0,
    hoa_fee: hoaFee = 0,
    home_insurance_fee: insuranceFee = 0,
    property_tax_rate: propertyTaxRate = 0,
  } = home || {};

  // Loan calculation logic
  const loanAmount = homePrice - downPayment;
  const annualInterest = loanAmount * (interestRate / 100);
  const totalInterest = annualInterest * selectedYear;
  const propertyTax = homePrice * (propertyTaxRate / 100) * selectedYear;
  const totalCost =
    totalInterest + loanAmount + hoaFee * 12 * selectedYear + insuranceFee * selectedYear + propertyTax;

  // Pie chart data breakdown
  const pieData = [
    { id: "Loan Amount", value: loanAmount || 0, label: "Loan Amount" },
    { id: "Interest Payment", value: totalInterest || 0, label: "Interest Payment" },
    { id: "Property Taxes", value: propertyTax || 0, label: "Property Taxes" },
    { id: "HOA & Insurance", value: hoaFee * 12 * selectedYear + insuranceFee * selectedYear, label: "HOA & Insurance" },
  ];

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h3 style={{ fontWeight: "bold" }}>Home Purchase Breakdown</h3>
      <p>Understand the breakdown of costs when purchasing a home.</p>

      {/* Dropdown for selecting year */}
      <Dropdown onSelect={(e) => setSelectedYear(parseInt(e))} className="mb-3">
        <Dropdown.Toggle variant="primary">
          Select Year: {selectedYear}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {[5, 10, 15, 20, 25, 30].map((year) => (
            <Dropdown.Item key={year} eventKey={year}>
              {year} Years
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {/* Cost Summary Section */}
      <Row className="justify-content-center mb-4">
        <Col md={3}>
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Total Cost</Card.Title>
              <Card.Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                ${totalCost.toLocaleString()}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Loan Amount</Card.Title>
              <Card.Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                ${loanAmount.toLocaleString()}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Down Payment</Card.Title>
              <Card.Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                ${downPayment.toLocaleString()}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Interest Paid</Card.Title>
              <Card.Text style={{ fontSize: "24px", fontWeight: "bold" }}>
                ${totalInterest.toLocaleString()}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Pie Chart Section */}
      <div style={{ height: "400px" }}>
        <ResponsivePie
          data={pieData}
          margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          colors={{ scheme: "nivo" }}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          arcLabel={(e) => `$${e.value.toLocaleString()}`}
          arcLinkLabelsTextColor="#333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          tooltip={({ datum }) => (
            <div style={{ background: "white", padding: "10px", borderRadius: "5px" }}>
              <strong>{datum.label}: </strong>${datum.value.toLocaleString()} 
              ({((datum.value / totalCost) * 100).toFixed(2)}%)
            </div>
          )}
        />
      </div>

      <div style={{ marginTop: "30px", fontSize: "18px" }}>
        <h4>Summary</h4>
        <p>Home Price: <strong>${homePrice.toLocaleString()}</strong></p>
        <p>Down Payment: <strong>${downPayment.toLocaleString()}</strong></p>
        <p>Interest Rate: <strong>{interestRate}%</strong></p>
        <p>Monthly HOA Fees: <strong>${hoaFee.toLocaleString()}</strong></p>
        <p>Property Tax Rate: <strong>{propertyTaxRate}%</strong></p>
      </div>
    </div>
  );
};

export default PurchaseNewHome;
