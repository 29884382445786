import React from "react";
import { useSelector } from "react-redux";
import { ResponsiveFunnel } from "@nivo/funnel";

const TapIntoEquity = () => {
  // Retrieve home data from Redux store
  const home = useSelector((state) => state.home.selected_home_details);

  // Destructuring values from Redux with default fallbacks
  const {
    home_price: homePrice = 0,
    down_payment: downPayment = 0,
    interest_rate: interestRate = 3, // Assuming a 3% annual interest rate
    term_months: loanTermMonths = 360, // Default 30 years (360 months)
  } = home || {};

  // Function to calculate home equity growth over years
  const calculateEquity = (years) => {
    const appreciationRate = 3; // 3% assumed appreciation
    const appreciatedValue = homePrice * Math.pow(1 + appreciationRate / 100, years);
    const loanAmount = homePrice - downPayment;
    const monthlyInterestRate = (interestRate / 100) / 12;
    const monthsElapsed = years * 12;

    // Loan balance reduction over years (basic amortization calculation)
    const remainingLoanBalance =
      (loanAmount * Math.pow(1 + monthlyInterestRate, monthsElapsed) - 
      (Math.pow(1 + monthlyInterestRate, monthsElapsed) - 1) / monthlyInterestRate) /
      loanTermMonths;

    return Math.max(appreciatedValue - remainingLoanBalance, 0); // Ensure positive equity
  };

  // Generate equity data based on selected home details
  const equityData = [
    { id: "5 Years", value: calculateEquity(5), label: "5 yrs" },
    { id: "10 Years", value: calculateEquity(10), label: "10 yrs" },
    { id: "15 Years", value: calculateEquity(15), label: "15 yrs" },
    { id: "20+ Years", value: calculateEquity(20), label: "20 yrs..." },
  ];

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h3 style={{ fontWeight: "bold" }}>Tap Into Equity</h3>
      <p style={{ fontSize: "18px" }}>
        Explore how your home equity accumulates over time with different appreciation periods.
      </p>

      <div style={{ height: "500px", width: "60%", margin: "0 auto" }}>
        <ResponsiveFunnel
          data={equityData}
          margin={{ top: 40, right: 20, bottom: 20, left: 120 }}
          valueFormat={(value) => `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`}
          colors={{ scheme: "nivo" }}
          borderWidth={20}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          label={(d) => `${d.label}`}
          labelColor={{ from: "color", modifiers: [["darker", 2]] }}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              translateX: 80,
              itemWidth: 100,
              itemHeight: 20,
              itemsSpacing: 5,
              symbolSize: 20,
            },
          ]}
        />
      </div>

      <div style={{ marginTop: "30px", fontSize: "18px" }}>
        <h4>Understanding Equity Growth</h4>
        <p>
          With a down payment of <strong>${downPayment.toLocaleString()}</strong> and an assumed appreciation
          rate of <strong>3%</strong>, your estimated home equity will grow over time.
        </p>
      </div>
    </div>
  );
};

export default TapIntoEquity;
