import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setHomes, updateSelectedHome } from "../../../../state/home/homeSlice";
import { fetchHomes } from "../../../../apiFetch/fetchHomes";
import IndividualHome from "./IndividualHome";
import CompareMode from "./CompareMode";
import "./PresentationPage.css";

const PresentationPage = () => {
  const dispatch = useDispatch();

  // Redux state for selected client, homes, and selected home
  const selectedClientId = useSelector((state) => state.client.selected_client_id);
  const homes = useSelector((state) =>
    Object.values(state.home.homes).filter((home) => home.client_id === selectedClientId)
  );
  const selectedHomeId = useSelector((state) => state.home.selected_home_id);

  // Additional client slice data
  const affordability = useSelector((state) => state.clientAffordability.selected_affordability_details);
  const assumptions = useSelector((state) => state.clientAssumptions.selected_assumptions);
  const contactInfo = useSelector((state) => state.clientContact.selected_contact_details);
  const expenditure = useSelector((state) => state.clientExpenditure.selected_expenditure);
  const goals = useSelector((state) => state.clientGoals.selected_goals);
  const clientInfo = useSelector((state) => state.clientInfo.selected_client_info);

  // Local state for toggling compare mode
  const [compareMode, setCompareMode] = React.useState(false);

  // Fetch homes when the selected client changes
  useEffect(() => {
    if (selectedClientId) {
      fetchHomes(selectedClientId, (fetchedHomes) => {
        dispatch(setHomes(fetchedHomes));
      });
    }
  }, [selectedClientId, dispatch]);

  // Auto-select the first home if no home is selected and homes are available
  useEffect(() => {
    if (!compareMode && homes.length > 0 && (selectedHomeId === -1 || selectedHomeId === null)) {
      dispatch(updateSelectedHome(homes[0].home_id));
    }
  }, [homes, selectedHomeId, compareMode, dispatch]);

  // Find the selected home object
  const selectedHome = homes.find((home) => home.home_id === selectedHomeId);

  return (
    <div className="presentation-page">
      <div className="controls">
        {!compareMode && (
          <>
            <select
              onChange={(e) => {
                const homeId = parseInt(e.target.value, 10);
                dispatch(updateSelectedHome(homeId));
              }}
              value={selectedHomeId || ""}
            >
              <option value="" disabled>
                Select a Home
              </option>
              {homes.map((home) => (
                <option key={home.home_id} value={home.home_id}>
                  {home.address}
                </option>
              ))}
            </select>
            <button onClick={() => setCompareMode(true)}>Compare Mode</button>
          </>
        )}
      </div>
  
      {!compareMode ? (
        selectedHome ? (
          <>
            <IndividualHome
              home={selectedHome}
              affordability={affordability}
              assumptions={assumptions}
              contactInfo={contactInfo}
              expenditure={expenditure}
              goals={goals}
              clientInfo={clientInfo}
            />
          </>
        ) : (
          <div>Loading home details...</div>
        )
      ) : (
        <CompareMode homes={homes} setCompareMode={setCompareMode} />
      )}
    </div>
  );  
};

export default PresentationPage;
