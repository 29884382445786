import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientInfo: {}, // All client info stored by `client_id`
  selected_client_info: {
    client_id: -1,
    presentation_type: "",
    strategy_template: "",
    client_type: "",
    rent_vs_own: "",
    referred_by: "",
    partner_email: "",
    presentation_name: "",
    co_borrower_first_name: "",
    co_borrower_last_name: "",
    co_borrower_dob: "",
    current_address_street: "",
    current_address_city: "",
    current_address_state: "",
    current_address_zip: "",
    current_estimated_home_value: 0,
    is_first_time_buyer: false,
    partner_phone: "",
  },
};

export const clientInfoSlice = createSlice({
  name: "clientInfo",
  initialState,
  reducers: {
    // Set all client info at once
    setClientInfo: (state, action) => {
      const infoArray = action.payload;
      state.clientInfo = infoArray.reduce((acc, info) => {
        acc[info.client_id] = info; // Use `client_id` as the key
        return acc;
      }, {});
    },
    // Add a new client and set as selected
    addClientInfoAndSelect: (state, action) => {
      const newClientInfo = action.payload;
      state.clientInfo[newClientInfo.client_id] = newClientInfo; // Add new client
      state.selected_client_info = { ...newClientInfo }; // Set as selected client
    },
    // Dynamically update selected client info or reset to default
    updateSelectedClientInfo: (state, action) => {
      const client_id = action.payload;
      if (state.clientInfo[client_id]) {
        state.selected_client_info = {
          ...state.clientInfo[client_id],
        };
      } else {
        // Reset to default and set client_id
        state.selected_client_info = {
          client_id,
          presentation_type: "",
          strategy_template: "",
          client_type: "",
          rent_vs_own: "",
          referred_by: "",
          partner_email: "",
          presentation_name: "",
          co_borrower_first_name: "",
          co_borrower_last_name: "",
          co_borrower_dob: "",
          current_address_street: "",
          current_address_city: "",
          current_address_state: "",
          current_address_zip: "",
          current_estimated_home_value: 0,
          is_first_time_buyer: false,
          partner_phone: "",
        };
      }
    },
    updateSelectedClientField: (state, action) => {
      const { field, value } = action.payload;
      state.selected_client_info[field] = value;
    },
    // Individual field updates for selected client info remain unchanged
    updatePresentationType: (state, action) => {
      state.selected_client_info.presentation_type = action.payload;
    },
    updateStrategyTemplate: (state, action) => {
      state.selected_client_info.strategy_template = action.payload;
    },
    updateClientType: (state, action) => {
      state.selected_client_info.client_type = action.payload;
    },
    updateRentVsOwn: (state, action) => {
      state.selected_client_info.rent_vs_own = action.payload;
    },
    updateReferredBy: (state, action) => {
      state.selected_client_info.referred_by = action.payload;
    },
    updatePartnerEmail: (state, action) => {
      state.selected_client_info.partner_email = action.payload;
    },
    updatePresentationName: (state, action) => {
      state.selected_client_info.presentation_name = action.payload;
    },
    updateCoBorrowerFirstName: (state, action) => {
      state.selected_client_info.co_borrower_first_name = action.payload;
    },
    updateCoBorrowerLastName: (state, action) => {
      state.selected_client_info.co_borrower_last_name = action.payload;
    },
    updateCoBorrowerDOB: (state, action) => {
      state.selected_client_info.co_borrower_dob = action.payload;
    },
    updateCurrentAddressStreet: (state, action) => {
      state.selected_client_info.current_address_street = action.payload;
    },
    updateCurrentAddressCity: (state, action) => {
      state.selected_client_info.current_address_city = action.payload;
    },
    updateCurrentAddressState: (state, action) => {
      state.selected_client_info.current_address_state = action.payload;
    },
    updateCurrentAddressZip: (state, action) => {
      state.selected_client_info.current_address_zip = action.payload;
    },
    updateCurrentEstimatedHomeValue: (state, action) => {
      state.selected_client_info.current_estimated_home_value = action.payload;
    },
    updateIsFirstTimeBuyer: (state, action) => {
      state.selected_client_info.is_first_time_buyer = action.payload;
    },
    updatePartnerPhone: (state, action) => {
      state.selected_client_info.partner_phone = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setClientInfo,
  addClientInfoAndSelect,
  updateSelectedClientInfo,
  updatePresentationType,
  updateStrategyTemplate,
  updateClientType,
  updateRentVsOwn,
  updateReferredBy,
  updatePartnerEmail,
  updatePresentationName,
  updateCoBorrowerFirstName,
  updateCoBorrowerLastName,
  updateCoBorrowerDOB,
  updateCurrentAddressStreet,
  updateCurrentAddressCity,
  updateCurrentAddressState,
  updateCurrentAddressZip,
  updateCurrentEstimatedHomeValue,
  updateIsFirstTimeBuyer,
  updatePartnerPhone,
  updateSelectedClientField,
  reset,
} = clientInfoSlice.actions;

export default clientInfoSlice.reducer;
