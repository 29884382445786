import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink, Outlet } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'; // Import Dropdown from react-bootstrap
import { useSelector, useDispatch } from 'react-redux';
import { handleSignOut } from './pages/Settings/handleSignOut'; // Import your handleSignOut function
import './HeaderLayout.css';

const HeaderLayout = () => {
  const selectedClient = useSelector(state => state.client.selected_client_id);
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchCurrentUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/current_user`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const userData = await response.json();
      if (response.ok) {
        setCurrentUser(userData.email);
      } else {
        navigate('/');
      }
    } catch (error) {
      navigate('/');
    }
  };

  const navItems = [
    { text: 'Clients', to: '/HeaderLayout/Clients', icon: 'pi pi-users' },
    // { text: 'Inputs', to: '/HeaderLayout/Inputs', icon: 'pi pi-plus' },
    // { text: 'Simple', to: '/HeaderLayout/Simple', icon: 'pi pi-chart-line' },
    // { text: 'Edit Client', to: '/HeaderLayout/EditClient', icon: 'pi pi-pencil' },
  ];

  return (
    <div className="layout-container">
      <header className="header d-flex justify-content-between align-items-center">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + "/assets/pf.png"} alt="logo" className="logo-text" />
        </div>
        <div className="user-info d-flex align-items-center">
          <span className="user-email mr-2">{currentUser}</span>
          <Dropdown>
            <Dropdown.Toggle className="custom-dropdown-toggle">
              <i className="pi pi-user mr-2"></i> Account
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate('/SettingsLayout/Contact')}>
                <i className="pi pi-cog mr-2"></i> Settings
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSignOut(dispatch, navigate)}>
                <i className="pi pi-sign-out mr-2"></i> Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <div className="sidebar">
        <ul className="navbar-nav flex-column">
          {navItems.map((item, index) => (
            <li className="nav-item" key={index}>
              <NavLink to={item.to} className="nav-link">
                <i className={item.icon + " mr-2"}></i>
                <span>{item.text}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      <main className="main-content">
        <Outlet />
      </main>
    </div>
  );
};

export default HeaderLayout;
