import React from 'react';
import { Link } from 'react-router-dom';
import './VerificationSuccess.css'; // Reuse the same CSS or create a new one

const VerificationError = () => {
  return (
    <div className="verification-container">
      <div className="verification-card error">
        <h1>❌ Oops!</h1>
        <p>We couldn't verify your email. The token might be invalid or expired.</p>
        <Link to="/register">
          <button className="verification-btn">Register Again</button>
        </Link>
      </div>
    </div>
  );
};

export default VerificationError;
