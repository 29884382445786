import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { updateSelectedHomeDetails, setHomes } from '../../../../state/home/homeSlice'; // Import Redux actions
import { formatNumber } from '../../../../utils/mathUtils';
import HomeDetailsChart from './HomeDetailsChart';
import './HomeDetails.css';
import '../../../components/clientComps/ComparisonSectionClient.css';
import Disclosure from '../../../components/Disclosure';

const HomeDetails = () => {
  const { homeId } = useParams();
  const dispatch = useDispatch();

  // Access Redux state
  const selectedClient= useSelector(state => state.client.selected_client_id);
  const selectedHomeDetails = useSelector(state => state.home.selected_home_details);
  const clientHomes = useSelector(state => Object.values(state.home.homes));

  const [loanDetails, setLoanDetails] = useState({
    term_months: 360,
    loan_type: 'Fixed',
    interest_only_months: 0,
    balloon_payment_months: 0,
  });
  const [comparisonResults, setComparisonResults] = useState([]);
  const [expandedYear, setExpandedYear] = useState(null);

  useEffect(() => {
    if (homeId && selectedClient) {
      fetchHomeDetails(homeId);
      fetchClientHomes(selectedClient);
    }
  }, [homeId, selectedClient]);

  const fetchHomeDetails = async (homeId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_home_details?home_id=${homeId}`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const homeData = await response.json();
      if (response.ok) {
        dispatch(updateSelectedHomeDetails(homeData)); // Update Redux state
        setLoanDetails({
          term_months: homeData.term_months || 360,
          loan_type: homeData.loan_type || 'Fixed',
          interest_only_months: homeData.interest_only_months || 0,
          balloon_payment_months: homeData.balloon_payment_months || 0,
        });
        calculateComparisonData(homeData); // Calculate comparison data when home details are fetched
      } else {
        console.error('Fetch home details failed:', homeData.status);
      }
    } catch (error) {
      console.error('Error fetching home details:', error);
    }
  };

  const fetchClientHomes = async (clientId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_homes?client_id=${clientId}`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const homesData = await response.json();
      if (response.ok) {
        dispatch(setHomes(homesData)); // Update Redux state
      } else {
        console.error('Fetch client homes failed:', homesData.status);
      }
    } catch (error) {
      console.error('Error fetching client homes:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoanDetails((prevLoanDetails) => ({
      ...prevLoanDetails,
      [name]: value,
    }));
    dispatch(updateSelectedHomeDetails({ [name]: value })); // Update Redux state
  };

  const saveHomeDetails = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_home/${homeId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...selectedHomeDetails,
          ...loanDetails
        }),
      });

      const result = await response.json();
      if (response.ok) {
        console.log('Home details updated successfully.');
      } else {
        console.error('Update home details failed:', result.status);
      }
    } catch (error) {
      console.error('Error updating home details:', error);
    }
  };

  // Calculate comparison data for the home
  const calculateComparisonData = (home) => {
    const home_price = home.home_price;
    const down_payment = home.down_payment;
    const interest_rate = home.interest_rate;
    const rent_amount = home.rent_amount;
    const rent_increases = home.rent_increases || 0.03;

    if (home_price > 0 && interest_rate > 0) {
      const years = [5, 10, 15, 20]; // Example years for comparison
      const data = years.map(year => ({
        years: year,
        futureRentExpense: calculateFutureRentExpenses(rent_amount, rent_increases, year),
        netWealth: calculateNetWealth(home_price, down_payment, interest_rate, year),
        sp500Value: calculateSP500Value(year)
      }));

      setComparisonResults(data);
    } else {
      console.log("Missing home data for calculations.");
    }
  };

  const calculateFutureRentExpenses = (rentAmount, rentIncreases, years) => {
    if (rentAmount !== undefined && rentIncreases !== undefined) {
      return 12 * rentAmount * ((Math.pow(1 + rentIncreases, years) - 1) / rentIncreases);
    }
    return 0;
  };

  const calculateNetWealth = (homePrice, downPayment, interestRate, years) => {
    if (homePrice !== undefined && downPayment !== undefined && interestRate !== undefined) {
      return (homePrice - downPayment) * Math.pow(1 + interestRate, years);
    }
    return 0;
  };

  const calculateSP500Value = (years) => {
    return years * 10000; // Placeholder calculation
  };

  const handleExpandYear = (year) => {
    setExpandedYear(expandedYear === year ? null : year);
  };

  if (!selectedHomeDetails || !selectedClient) {
    return <div>Loading...</div>;
  }

  return (
    <div>
    <div className="home-details">
      <h1>{selectedClient.name}</h1>
      <h2>{selectedHomeDetails.address}</h2>
      <div className="home-info-chart">
        <div className="home-info">
          <form onSubmit={saveHomeDetails}>
            <p>
              Type: 
              <input type="text" name="type" value={selectedHomeDetails.type} onChange={handleInputChange} />
            </p>
            <p>
              Price: 
              <input type="number" name="home_price" value={selectedHomeDetails.home_price} onChange={handleInputChange} />
            </p>
            <p>
              Down Payment: 
              <input type="number" name="down_payment" value={selectedHomeDetails.down_payment} onChange={handleInputChange} />%
            </p>
            <p>
              Interest Rate: 
              <input type="number" name="interest_rate" value={selectedHomeDetails.interest_rate} onChange={handleInputChange} />%
            </p>
            <p>
              Property Tax Rate: 
              <input type="number" name="property_tax_rate" value={selectedHomeDetails.property_tax_rate} onChange={handleInputChange} />%
            </p>
            <p>
              Home Insurance Fee: 
              <input type="number" name="home_insurance_fee" value={selectedHomeDetails.home_insurance_fee} onChange={handleInputChange} />
            </p>
            <p>
              PMI Rate: 
              <input type="number" name="pmi_rate" value={selectedHomeDetails.pmi_rate} onChange={handleInputChange} />%
            </p>
            <p>
              HOA: 
              <input type="number" name="hoa" value={selectedHomeDetails.hoa} onChange={handleInputChange} />
            </p>
            <p>
              Rent Amount: 
              <input type="number" name="rent_amount" value={selectedHomeDetails.rent_amount} onChange={handleInputChange} />
            </p>
            <h3>Loan Details</h3>
            <label>
              Term (months):
              <input
                type="number"
                name="term_months"
                value={loanDetails.term_months}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Loan type:
              <select
                name="loan_type"
                value={loanDetails.loan_type}
                onChange={handleInputChange}
              >
                <option value="Fixed">Fixed</option>
                <option value="ARM">ARM</option>
                <option value="HELOC">HELOC</option>
              </select>
            </label>
            <label>
              Interest only months:
              <input
                type="number"
                name="interest_only_months"
                value={loanDetails.interest_only_months}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Months till balloon payment:
              <input
                type="number"
                name="balloon_payment_months"
                value={loanDetails.balloon_payment_months}
                onChange={handleInputChange}
              />
            </label>
            <button type="submit">Save Home Details</button>
          </form>
        </div>
        <div className="home-chart">
          <HomeDetailsChart clientHomes={clientHomes} />
        </div>
      </div>
      
      {/* Rent vs Own Comparison Section */}
      <div className="comparison-section">
        <h1>Rent vs Own Comparison</h1>
        <div className="comparison-container">
          <table>
            <thead>
              <tr>
                <th>Years</th>
                <th>Rent Expense</th>
                <th>Own Net Wealth</th>
                <th>SP500 Value</th>
              </tr>
            </thead>
            <tbody>
              {comparisonResults.map((result) => (
                <React.Fragment key={result.years}>
                  <tr>
                    <td>{result.years}</td>
                    <td
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => handleExpandYear(result.years)}
                    >
                      ${formatNumber(result.futureRentExpense)}
                    </td>
                    <td
                      style={{ color: 'green', cursor: 'pointer' }}
                      onClick={() => handleExpandYear(result.years)}
                    >
                      ${formatNumber(result.netWealth)}
                    </td>
                    <td
                      style={{ color: 'black', cursor: 'pointer' }}
                      onClick={() => handleExpandYear(result.years)}
                    >
                      ${formatNumber(result.sp500Value)}
                    </td>
                  </tr>
                  {expandedYear === result.years && (
                    <tr>
                      <td colSpan="4">
                        <div className="comparison-box">
                          <p>Years: {result.years}</p>
                          <p>Future Rent Expense: ${formatNumber(result.futureRentExpense)}</p>
                          <p>Net Wealth: ${formatNumber(result.netWealth)}</p>
                          <p>SP500 Value: ${formatNumber(result.sp500Value)}</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
    <Disclosure></Disclosure>
    </div>
  );
};

export default HomeDetails;
