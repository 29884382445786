import React from 'react';
import './ConfirmEmailChange.css';

const ConfirmEmailChangeSuccess = () => {
  return (
    <div className="confirm-email-container">
      <h1>Email Change Successful</h1>
      <p>Your email has been successfully updated.</p>
      <a href="/" className="btn">Go to Home</a>
    </div>
  );
};

export default ConfirmEmailChangeSuccess;
