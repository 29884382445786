import React from 'react';

const Images = () => {
    return (
        <div>
            <h1>Images</h1>
        </div>
    );
};

export default Images;
