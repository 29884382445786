// src/utils/fetchClients.js

/**
 * Formats a date string into "yyyy-MM-dd" format.
 * @param {string} dateString - The date string to format.
 * @returns {string} - Formatted date string or an empty string if invalid.
 */
const formatDateForInput = (dateString) => {
  if (!dateString) return ""; // Handle empty or null input gracefully
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      // Check if the date is invalid
      console.error(`Invalid date: ${dateString}`);
      return ""; // Return empty string for invalid dates
    }
    return date.toISOString().split("T")[0]; // "yyyy-MM-dd"
  } catch (error) {
    console.error("Error formatting date:", dateString, error);
    return ""; // Return an empty string if the date parsing fails
  }
};

/**
 * Fetches client data from the server and formats the dates.
 * @param {function} setClients - Function to update clients in state.
 */
export const fetchClients = async (setClients) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.log("No token found, please log in.");
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_clients`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();
    if (response.ok) {
      // Normalize dates in the fetched client data
      const formattedClients = result.map((client) => ({
        ...client,
        date_of_birth: client.date_of_birth
          ? formatDateForInput(client.date_of_birth)
          : "", // Format or leave empty if null
      }));

      console.log("FORMATTED fetched Clients:", formattedClients); // Log fetched clients
      setClients(formattedClients);
    } else {
      console.error("Error fetching clients:", result.status);
    }
  } catch (error) {
    console.error(`An error occurred while fetching clients: ${error.message}`);
  }
};
