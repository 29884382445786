import { setLoading } from '../state/loading/loadingSlice';
import { updateSelectedClient as updateClientInClientSlice } from '../state/client/clientSlice';
import { setHomes, updateSelectedClientForHome } from '../state/home/homeSlice';
import { fetchHomes } from '../apiFetch/fetchHomes';

const homeMiddleware = (store) => (next) => async (action) => {
  if (action.type === updateClientInClientSlice.type) {
    const clientId = action.payload;

    if (clientId) {
      try {
        // Start loading
        store.dispatch(setLoading(true));

        // Dispatch action to reset selected client in the home slice
        store.dispatch(updateSelectedClientForHome(clientId));

        const state = store.getState();
        const existingHomes = Object.values(state.home.homes).filter(
          (home) => home.client_id === clientId
        );

        // If homes for the selected client already exist in the store, do nothing
        if (existingHomes.length > 0) {
          store.dispatch(setLoading(false)); // Stop loading
          return next(action);
        }

        // Fetch homes for the client from the database
        const homesFromDb = await fetchHomes(clientId);
        if (homesFromDb) {
          // Dispatch action to set the fetched homes
          store.dispatch(setHomes(homesFromDb));
        }
      } catch (error) {
        console.error('Middleware: Error fetching client homes:', error);
      } finally {
        // Stop loading
        store.dispatch(setLoading(false));
      }
    }
  }

  return next(action);
};

export default homeMiddleware;
