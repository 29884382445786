import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  calculateTotalEquityGained,
  calculateEstimatedLoanInterest,
  calculateMonthlyPayment,
  calculateTaxWriteOffsPerYear,
  calculateEstimatedHomeAffordability,
} from './utils/equationsHome';
import MissingInfoAlert from '../../../../alerts/MissingInfoAlert';
import RentOwnSP500Chart from './RentOwnSPChart';
import ComparisonSection from '../../../components/ComparisonSection';
import SupportRetirement from "./goals/SupportRetirement";
import PayOffDebt from "./goals/PayOffDebt";
import LowerInterestRate from "./goals/LowerInterestRate";
import PurchaseNewHome from "./goals/PurchaseNewHome";
import ReducePayment from "./goals/ReducePayment";
import TapIntoEquity from "./goals/TapIntoEquity";

// import './IndividualHome.css';

const IndividualHome = ({
  home,
  affordability,
  assumptions = {},  // Ensure assumptions is never undefined
  contactInfo,
  expenditure,
  goals,
  clientInfo
}) => {
  const [missingFields, setMissingFields] = useState([]);
  const [purchaseDate, setPurchaseDate] = useState(new Date().toISOString().split('T')[0]);
  const [annualAppreciationRate, setAnnualAppreciationRate] = useState(3.5);
  const appreciationRateDecimal = annualAppreciationRate / 100;

  useEffect(() => {
    const missing = [];
    if (!home?.home_price) missing.push('Home Price');
    if (!home?.down_payment) missing.push('Down Payment');
    if (!home?.interest_rate) missing.push('Interest Rate');
    if (!home?.property_tax_rate) missing.push('Property Tax Rate');
    if (!home?.term_months) missing.push('Term Months');
    if (!purchaseDate) missing.push('Hypothetical Purchase Date');
    setMissingFields(missing);
  }, [home, purchaseDate]);

  if (!home) {
    return <div><p>No home selected.</p></div>;
  }

  const {
    home_price: homePriceRaw = 0,
    down_payment: downPaymentRaw = 0,
    interest_rate: interestRateRaw = 0,
    property_tax_rate: propertyTaxRateRaw = 0,
    term_months: termMonthsRaw = 0,
    address,
    type,
  } = home;

  const homePrice = parseFloat(homePriceRaw) || 0;
  const downPayment = parseFloat(downPaymentRaw) || 0;
  const interestRate = parseFloat(interestRateRaw) || 0;
  const propertyTaxRate = parseFloat(propertyTaxRateRaw) || 0;
  const termMonths = parseInt(termMonthsRaw, 10) || 0;

  const loanAmount = homePrice - downPayment;
  const annualInterestRate = interestRate / 100;
  const loanTermYears = termMonths / 12;
  const yearsElapsed = 0;

  // Provide default values for assumptions to prevent undefined errors
  const parsedAssumptions = {
    current_rent: parseFloat(assumptions?.current_rent) || 0,
    annual_rent_increase: parseFloat(assumptions?.annual_rent_increase) || 0.03,
    monthly_rent_insurance: parseFloat(assumptions?.monthly_rent_insurance) || 0,
    other_monthly_rent_expense: parseFloat(assumptions?.other_monthly_rent_expense) || 0,
  };

  const totalEquityGained =
    missingFields.length === 0
      ? calculateTotalEquityGained(homePrice, loanAmount, annualAppreciationRate, annualInterestRate, yearsElapsed, loanTermYears)
      : 0;

  const estimatedLoanInterest =
    missingFields.length === 0
      ? calculateEstimatedLoanInterest(loanAmount, annualInterestRate, termMonths)
      : 0;

  const monthlyPayment =
    missingFields.length === 0
      ? calculateMonthlyPayment(loanAmount, annualInterestRate, termMonths)
      : 0;

  const taxWriteOffsPerYear =
    missingFields.length === 0
      ? calculateTaxWriteOffsPerYear(estimatedLoanInterest, homePrice * (propertyTaxRate / 100))
      : 0;

  const estimatedHomeAffordability =
    missingFields.length === 0
      ? calculateEstimatedHomeAffordability(100000, 2000)
      : 0;

  return (
    <div>
      <MissingInfoAlert missingFields={missingFields} />
      <hr />

      <h2>{address}</h2>
      <p>Type: {type || 'N/A'}</p>
      <p>Hypothetical Purchase Date: {purchaseDate}</p>
      <p>Total Equity Gained: ${totalEquityGained.toLocaleString()}</p>
      <p>Estimated Loan Interest: ${estimatedLoanInterest.toLocaleString()}</p>
      <p>Monthly Payment: ${monthlyPayment.toLocaleString()}</p>
      <p>Tax Write-Offs Per Year: ${taxWriteOffsPerYear.toLocaleString()}</p>
      <p>Estimated Home Affordability: ${estimatedHomeAffordability.toLocaleString()}</p>
      <hr />

      <div>
        <label htmlFor="purchaseDate">Hypothetical Purchase Date:</label>
        <input
          type="date"
          id="purchaseDate"
          value={purchaseDate}
          onChange={(e) => setPurchaseDate(e.target.value)}
        />
      </div>
      <hr />

      <div>
        <label htmlFor="appreciationRate">Annual Home Appreciation Rate (%):</label>
        <input
          type="number"
          id="appreciationRate"
          value={annualAppreciationRate === 0 ? '' : annualAppreciationRate}
          step="0.1"
          onChange={(e) => {
            const value = e.target.value === '' ? '' : parseFloat(e.target.value);
            setAnnualAppreciationRate(value === '' ? '' : value);
          }}
          onBlur={(e) => {
            if (e.target.value === '') {
              setAnnualAppreciationRate(0);
            }
          }}
          style={{
            appearance: 'textfield',
            MozAppearance: 'textfield',
          }}
        />
      </div>
      <hr />

      <RentOwnSP500Chart home={home} annualAppreciationRate={appreciationRateDecimal} assumptions={parsedAssumptions} />
      <hr />

      <ComparisonSection annualAppreciationRate={appreciationRateDecimal} assumptions={parsedAssumptions}/>
      <hr />

      <div className="client-goals">
        <h2>Client Goals</h2>
        <hr />
        {goals?.support_retirement && (
          <>
            <SupportRetirement />
            <hr />
          </>
        )}
        {goals?.pay_off_debt && (
          <>
            <PayOffDebt />
            <hr />
          </>
        )}
        {goals?.lower_interest_rate && (
          <>
            <LowerInterestRate />
            <hr />
          </>
        )}
        {goals?.purchase_new_home && (
          <>
            <PurchaseNewHome />
            <hr />
          </>
        )}
        {goals?.reduce_payment && (
          <>
            <ReducePayment />
            <hr />
          </>
        )}
        {goals?.tap_into_equity && (
          <>
            <TapIntoEquity />
            <hr />
          </>
        )}
      </div>
    </div>
  );
};

IndividualHome.propTypes = {
  home: PropTypes.shape({
    home_id: PropTypes.number,
    address: PropTypes.string,
    type: PropTypes.string,
    home_price: PropTypes.number,
    down_payment: PropTypes.number,
    interest_rate: PropTypes.number,
    property_tax_rate: PropTypes.number,
    term_months: PropTypes.number,
  }),
  assumptions: PropTypes.shape({
    current_rent: PropTypes.number,
    annual_rent_increase: PropTypes.number,
    monthly_rent_insurance: PropTypes.number,
    other_monthly_rent_expense: PropTypes.number,
  }),
};

export default IndividualHome;
