import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  goals: {}, // Store all goals by client_id
  selected_goals: {
    id: null,
    client_id: null,
    support_retirement: false,
    pay_off_debt: false,
    lower_interest_rate: false,
    purchase_new_home: false,
    reduce_payment: false,
    reduce_payment_amount: null,
    tap_into_equity: false,
    other: false,
    other_description: "",
  },
};

export const clientGoalsSlice = createSlice({
  name: 'clientGoals',
  initialState,
  reducers: {
    setGoals: (state, action) => {
      const { client_id, goals } = action.payload;
      state.goals[client_id] = { ...goals };
    },
    addGoalsAndSelect: (state, action) => {
      const newGoals = action.payload;
      state.goals[newGoals.client_id] = newGoals; // Add new goals to goals
      state.selected_goals = { ...newGoals }; // Set as selected goals
    },
    updateSelectedGoalField: (state, action) => {
      const { field, value } = action.payload;
      state.selected_goals[field] = value;
    },
    selectGoals: (state, action) => {
      const { client_id } = action.payload;
      if (state.goals[client_id]) {
        state.selected_goals = { ...state.goals[client_id] };
      } else {
        // Reset if no goals exist
        state.selected_goals = {
          id: null,
          client_id,
          support_retirement: false,
          pay_off_debt: false,
          lower_interest_rate: false,
          purchase_new_home: false,
          reduce_payment: false,
          reduce_payment_amount: null,
          tap_into_equity: false,
          other: false,
          other_description: "",
        };
      }
    },
    reset: () => initialState,
  },
});

export const { setGoals, addGoalsAndSelect, updateSelectedGoalField, selectGoals, reset, } =
  clientGoalsSlice.actions;

export default clientGoalsSlice.reducer;
