import React, { useState, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import { useSelector } from "react-redux";

const LowerInterestRate = () => {
  // Select credit score and interest rate from Redux store
  const creditScore = useSelector(
    (state) => state.clientAffordability.selected_affordability_details.credit_status
  );

  const currentInterestRate = useSelector(
    (state) => state.clientAffordability.selected_affordability_details.savings_interest_rate
  );

  // Convert credit score to number (assuming it's stored as a string)
  const [selectedScore, setSelectedScore] = useState(Number(creditScore) || 500);

  useEffect(() => {
    if (creditScore) {
      setSelectedScore(Number(creditScore)); // Update state when Redux changes
    }
  }, [creditScore]);

  // Credit score categories and corresponding interest rates
  const creditScoreRanges = [
    { label: "Needs Work", minScore: 500, maxScore: 579, rate: 8.0 },
    { label: "Poor", minScore: 580, maxScore: 639, rate: 7.0 },
    { label: "Bad", minScore: 640, maxScore: 679, rate: 6.5 },
    { label: "Fair", minScore: 680, maxScore: 719, rate: 5.5 },
    { label: "Okay", minScore: 720, maxScore: 739, rate: 4.5 },
    { label: "Good", minScore: 740, maxScore: 759, rate: 3.9 },
    { label: "Pretty Good", minScore: 760, maxScore: 799, rate: 3.5 },
    { label: "Very Good", minScore: 800, maxScore: 849, rate: 3.0 },
    { label: "Exceptional", minScore: 850, maxScore: 900, rate: 2.8 },
  ];

  // Find the correct interest rate for the selected credit score
  const getInterestRate = (score) => {
    const foundRange = creditScoreRanges.find(
      (entry) => score >= entry.minScore && score <= entry.maxScore
    );
    return foundRange ? foundRange.rate : "N/A";
  };

  // Get the interest rate for the Redux stored credit score
  const currentRate = getInterestRate(selectedScore);

  // Prepare data for the step chart visualization
  // Prepare data for the step chart visualization without connecting different score ranges
const data = [
  {
    id: "Interest Rate",
    color: "hsl(217, 70%, 50%)",
    data: creditScoreRanges.flatMap((entry, index, array) => [
      { x: entry.minScore, y: entry.rate },
      { x: entry.maxScore, y: entry.rate },
      ...(index < array.length - 1 ? [{ x: entry.maxScore + 1, y: null }] : []), // Add null to break the connection
    ]),
  },
];


  return (
    <div style={{ width: "100%", textAlign: "center", padding: "20px" }}>
      <h3 style={{ fontWeight: "bold" }}>Lower Interest Rate</h3>
      <p>Explore how your credit score affects your interest rate and potential savings.</p>

      <div style={{ height: "500px" }}>
      <ResponsiveLine
  data={data}
  margin={{ top: 50, right: 30, bottom: 50, left: 80 }}
  xScale={{ type: "linear", min: 500, max: 900 }}
  yScale={{ type: "linear", min: 2, max: 9 }}
  axisLeft={{
    legend: "Interest Rate (%)",
    legendOffset: -50,
    tickSize: 5,
    tickPadding: 5,
  }}
  axisBottom={{
    legend: "Credit Score",
    legendOffset: 36,
    tickSize: 5,
    tickPadding: 5,
    format: (value) => value,
  }}
  colors={{ scheme: "nivo" }}
  pointSize={10}
  pointColor={{ theme: "background" }}
  pointBorderWidth={2}
  pointBorderColor={{ from: "serieColor" }}
  useMesh={true}
  enableGridX={true}
  enableGridY={true}
  curve="stepAfter"  // Add this line to make it a step chart
  markers={[
    {
      axis: "x",
      value: selectedScore,
      lineStyle: { stroke: "red", strokeWidth: 3 },
      legend: `Your Score: ${selectedScore}`,
      legendPosition: "top-left",
    },
  ]}
  legends={[
    {
      anchor: "top-right",
      direction: "row",
      translateX: 0,
      translateY: -40,
      itemsSpacing: 0,
      itemWidth: 100,
      itemHeight: 20,
      symbolSize: 12,
      symbolShape: "circle",
      itemDirection: "left-to-right",
    },
  ]}
/>

      </div>

      <div style={{ marginTop: "20px", padding: "20px" }}>
        <h4>Adjust Your Credit Score</h4>
        <input
          type="range"
          min="500"
          max="850"
          step="1"
          value={selectedScore}
          onChange={(e) => setSelectedScore(parseInt(e.target.value))}
          style={{ width: "80%" }}
        />
        <p>Selected Credit Score: <strong>{selectedScore}</strong></p>
      </div>

      <div style={{ marginTop: "30px", fontSize: "18px" }}>
        {/* <p>
          Your current interest rate: <strong>{currentRate}%</strong>
        </p> */}
        <p>
          Based on your credit score of <strong>{selectedScore}</strong>, you could qualify for a rate of{" "}
          <strong>{getInterestRate(selectedScore)}%</strong>.
        </p>
        <p>Improving your credit score can help you secure better rates and save more.</p>
      </div>
    </div>
  );
};

export default LowerInterestRate;
