import React from 'react';

const Disclosure = () => {
  return (
    <div style={styles.container}>
      <h3 style={styles.header}>Disclosure</h3>
      <p style={styles.text}>
        The information provided on this website is for general informational purposes only and may not be 100% accurate or applicable to your specific situation. It is not intended to be a substitute for professional advice. Users are strongly encouraged to consult with a licensed CPA or tax professional before making any decisions regarding their taxes.
      </p>
      <p style={styles.text}>
        The use of the information on this website is at the user’s own risk. The website and its owners, operators, and contributors are not responsible for any errors, omissions, or outcomes resulting from its use. By accessing this website, users agree that the website and its affiliates are not liable for any decisions, actions, or consequences arising from reliance on the information provided.
      </p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '100%', // Limits the width to make it smaller and centered
    padding: '15px', // Compact padding
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: '5px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.4',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '20px auto', // Centers the container and adds spacing
    textAlign: 'left', // Left-aligns for better readability
  },
  header: {
    fontSize: '14px', // Slightly smaller header
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#333',
    textAlign: 'center', // Center-aligns the header
  },
  text: {
    fontSize: '12px', // Smaller text for a more compact appearance
    color: '#666',
    marginBottom: '10px',
  },
};

export default Disclosure;
