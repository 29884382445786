import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ResponsivePie } from "@nivo/pie";
import { Dropdown } from "react-bootstrap";

const PayOffDebt = () => {
  const [selectedYear, setSelectedYear] = useState(5);

  // Fetch home details, client assumptions, and affordability data from Redux store
  const home = useSelector((state) => state.home.selected_home_details);
  const assumptions = useSelector((state) => state.clientAssumptions.selected_assumptions);
  const affordability = useSelector((state) => state.clientAffordability.selected_affordability_details);

  if (!home || !assumptions || !affordability) {
    return <p>Loading data...</p>;
  }

  // Extract values from Redux store
  const {
    home_price: homePrice,
    down_payment: downPayment,
    term_months: termMonths,
    hoa_fee: hoaFees,
  } = home;

  const {
    current_rent: rentAmount,
    other_monthly_rent_expense: otherRentExpenses = rentAmount * 0.05, // Default to 5% of rent
  } = assumptions;

  // Default percentage values for utilities and insurance calculations
  const UTILITIES_PERCENTAGE = 0.05; // 5% of mortgage or rent
  const INSURANCE_PERCENTAGE = 0.03; // 3% of mortgage or rent

  // Calculate values based on home price and rent amount
  const loanAmount = homePrice - downPayment;
  const mortgagePayment = loanAmount * 0.04 * selectedYear; // 4% of loan over selected years
  const hoaFeesTotal = hoaFees * 12 * selectedYear;
  const insuranceOwn = mortgagePayment * INSURANCE_PERCENTAGE;
  const utilitiesOwn = mortgagePayment * UTILITIES_PERCENTAGE;
  const netGain = mortgagePayment - (hoaFeesTotal + insuranceOwn + utilitiesOwn);

  const estimatedRent = affordability.current_annual_gross_income
    ? affordability.current_annual_gross_income * 0.3
    : rentAmount * 12;

  const utilitiesRent = estimatedRent * UTILITIES_PERCENTAGE;
  const insuranceRent = estimatedRent * INSURANCE_PERCENTAGE;
  const netLoss = estimatedRent * selectedYear + insuranceRent + otherRentExpenses * 12 * selectedYear + utilitiesRent;

  // Data categories with calculated values over selected years
  const ownData = [
    { id: "Mortgage", value: mortgagePayment, label: "Mortgage" },
    { id: "HOA Fees", value: hoaFeesTotal, label: "HOA Fees" },
    { id: "Insurance", value: insuranceOwn, label: "Insurance" },
    { id: "Utilities", value: utilitiesOwn, label: "Utilities" },
  ];

  const rentData = [
    { id: "Rent", value: estimatedRent * selectedYear, label: "Rent" },
    { id: "Rent Insurance", value: insuranceRent, label: "Rent Insurance" },
    { id: "Other Expenses", value: otherRentExpenses * 12 * selectedYear, label: "Other Expenses" },
    { id: "Utilities", value: utilitiesRent, label: "Utilities" },
  ];

  // Function to calculate projected percentages based on selected year
  const calculatePercentageData = (data) => {
    const total = data.reduce((sum, item) => sum + item.value, 0);
    return data.map((item) => ({
      id: item.id,
      label: item.label,
      value: ((item.value / total) * 100).toFixed(1), // Convert to percentage
      originalValue: item.value, // Store the original value for tooltip
    }));
  };

  const projectedOwnData = calculatePercentageData(ownData);
  const projectedRentData = calculatePercentageData(rentData);

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h3>Pay Off Debt</h3>
      <p>Demonstrating how their initial investment will turn into savings over time.</p>

      {/* Dropdown for selecting year */}
      <Dropdown onSelect={(e) => setSelectedYear(parseInt(e))}>
        <Dropdown.Toggle variant="primary">Select Year: {selectedYear}</Dropdown.Toggle>
        <Dropdown.Menu>
          {[5, 10, 15, 20, 25, 30].map((year) => (
            <Dropdown.Item key={year} eventKey={year}>
              {year} Years
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <div style={{ display: "flex", justifyContent: "space-around", marginTop: "40px" }}>
        {/* Own Pie Chart */}
        <div style={{ width: "45%", height: "400px" }}>
          <h4>Own Breakdown</h4>
          <ResponsivePie
            data={projectedOwnData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.6}
            padAngle={0.7}
            cornerRadius={5}
            colors={{ scheme: "paired" }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            arcLabel={(d) => `${d.value}%`}
            enableArcLabels={true}
            arcLabelSkipAngle={10}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 3]] }}
            enableArcLinkLabels={true}
            arcLinkLabelsSkipAngle={5}
            arcLinkLabelsTextColor={{ from: "color", modifiers: [["darker", 3]] }}
            tooltip={({ datum }) => (
              <div
                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <strong>{datum.label}</strong>: ${datum.data.originalValue.toLocaleString()}
              </div>
            )}
            layers={[
              "arcs",
              "arcLabels",
              "arcLinkLabels",
              "legends",
              ({ centerX, centerY }) => (
                <text
                  x={centerX}
                  y={centerY}
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{ fontSize: 18, fontWeight: "bold" }}
                >
                  ${netGain.toLocaleString()}
                </text>
              ),
            ]}
          />
        </div>

        {/* Rent Pie Chart */}
        <div style={{ width: "45%", height: "400px" }}>
          <h4>Rent Breakdown</h4>
          <ResponsivePie
            data={projectedRentData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.6}
            padAngle={0.7}
            cornerRadius={5}
            colors={{ scheme: "category10" }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            arcLabel={(d) => `${d.value}%`}
            enableArcLabels={true}
            arcLabelSkipAngle={10}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 3]] }}
            enableArcLinkLabels={true}
            arcLinkLabelsSkipAngle={5}
            arcLinkLabelsTextColor={{ from: "color", modifiers: [["darker", 3]] }}
            tooltip={({ datum }) => (
              <div
                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <strong>{datum.label}</strong>: ${datum.data.originalValue.toLocaleString()}
              </div>
            )}
            layers={[
              "arcs",
              "arcLabels",
              "arcLinkLabels",
              "legends",
              ({ centerX, centerY }) => (
                <text
                  x={centerX}
                  y={centerY}
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{ fontSize: 18, fontWeight: "bold" }}
                >
                  -${netLoss.toLocaleString()}
                </text>
              ),
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default PayOffDebt;
