export async function changeEmail(formData) {
  const newEmail = formData.get('newEmail');
  const password = formData.get('emailPassword');

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/change_email`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ new_email: newEmail, password }),
    });

    if (response.ok) {
      return { success: true, message: 'Email updated successfully' };
    } else {
      const errorData = await response.json();
      return { success: false, message: errorData.msg || 'Failed to update email' };
    }
  } catch (error) {
    console.error('Error changing email:', error);
    return { success: false, message: 'An error occurred while changing email' };
  }
}

export async function getCurrentEmail() {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_email`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { success: true, email: data.email };
    } else {
      const errorData = await response.json();
      return { success: false, message: errorData.msg || 'Failed to fetch email' };
    }
  } catch (error) {
    console.error('Error fetching email:', error);
    return { success: false, message: 'An error occurred while fetching email' };
  }
}

export async function changePassword(formData) {
  const oldPassword = formData.get('oldPassword');
  const newPassword = formData.get('newPassword');

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/change_password`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ old_password: oldPassword, new_password: newPassword }),
    });

    if (response.ok) {
      return { success: true, message: 'Password updated successfully' };
    } else {
      const errorData = await response.json();
      return { success: false, message: `Failed to update password: ${errorData.msg}` };
    }
  } catch (error) {
    console.error('Error changing password:', error);
    return { success: false, message: 'An error occurred while changing password' };
  }
}

export async function deleteAccount(formData) {
  const password = formData.get('deletePassword');

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/delete_account`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    });

    if (response.ok) {
      return { success: true, message: 'Account deleted successfully' };
    } else {
      const errorData = await response.json();
      return { success: false, message: `Failed to delete account: ${errorData.msg}` };
    }
  } catch (error) {
    console.error('Error deleting account:', error);
    return { success: false, message: 'An error occurred while deleting account' };
  }
}
