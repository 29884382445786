import { updateSelectedClient as updateClientInClientSlice } from '../state/client/clientSlice';
import { setContactInfo, updateSelectedContactForUser } from '../state/user/userContactSlice';
import { fetchUserContact } from '../apiFetch/fetchUserContact';

const userContactMiddleware = (store) => (next) => async (action) => {
  if (action.type === updateClientInClientSlice.type) {
    const clientId = action.payload;

    if (clientId) {
      try {
        // Dispatch action to reset selected contact info in the user contact slice
        store.dispatch(updateSelectedContactForUser(clientId));

        const state = store.getState();
        const existingContactInfo = state.userContact.contactInfo[clientId];

        // If contact info for the selected client already exists in the store, do nothing
        if (existingContactInfo) {
          return next(action);
        }

        // Fetch contact info for the client from the database
        const contactInfoFromDb = await fetchUserContact(clientId);
        if (contactInfoFromDb) {
          // Dispatch action to set the fetched contact info
          store.dispatch(setContactInfo(contactInfoFromDb));
        }
      } catch (error) {
        console.error('Middleware: Error fetching user contact info:', error);
      }
    }
  }

  return next(action);
};

export default userContactMiddleware;
