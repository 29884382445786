import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateCurrentRent,
  updateAnnualRentIncrease,
  updateMonthlyRentInsurance,
  updateOtherMonthlyRentExpense,
  updateSelectedAssumptions,
} from "../../../../../state/client/clientAssumptionsSlice";
import "./AssumptionsForm.css";
import FormattedNumberInput from "../utils/FormattedNumberInput";

const BuyerStatusAndRentalInfoForm = () => {
  const dispatch = useDispatch();

  // Redux State
  const selectedClientId = useSelector(
    (state) => state.client.selected_client_id
  );
  const clientDetails = useSelector(
    (state) => state.clientInfo.selected_client_info
  );
  const assumptions = useSelector(
    (state) => state.clientAssumptions.selected_assumptions
  );


  // Sync selected assumptions with the selected client ID
  useEffect(() => {
    if (selectedClientId) {
      dispatch(updateSelectedAssumptions(selectedClientId));
    }
  }, [selectedClientId, dispatch]);

  // Handle changes to input fields
  const handleInputChange = (field, value) => {
    switch (field) {
      case "current_rent":
        dispatch(updateCurrentRent(value));
        break;
      case "annual_rent_increase":
        dispatch(updateAnnualRentIncrease(value));
        break;
      case "monthly_rent_insurance":
        dispatch(updateMonthlyRentInsurance(value));
        break;
      case "other_monthly_rent_expense":
        dispatch(updateOtherMonthlyRentExpense(value));
        break;
      default:
        break;
    }
  };

  // Save assumptions to the backend
  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/client_assumptions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            client_id: selectedClientId,
            ...assumptions,
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Assumptions saved successfully:", result);
        alert("Assumptions saved successfully!");
      } else {
        console.error("Failed to save assumptions.");
        alert("Failed to save assumptions. Please try again.");
      }
    } catch (error) {
      console.error("Error saving assumptions:", error);
      alert("An error occurred while saving assumptions. Please try again.");
    }
  };

  // Ensure that assumptions and `is_first_time_buyer` are properly loaded
  if (!clientDetails.is_first_time_buyer) {
    return (
      <p style={{ fontSize: "14px", textAlign: "center", color: "gray" }}>
        Buyer is not marked as a first-time buyer.
      </p>
    );
  }

  return (
    <div className="assumptions-form">
      <h2>Buyer Status & Rental Info</h2>
      <form>
        <div className="form-group">
          <label htmlFor="current-rent">Current Rent</label>
          <FormattedNumberInput
            id="current-rent"
            value={assumptions?.current_rent || ""}
            onChange={(value) => handleInputChange("current_rent", value)}
            placeholder="Enter current rent"
          />
        </div>

        <div className="form-group">
          <label htmlFor="annual-rent-increase">Annual Rent Increase (%)</label>
          <FormattedNumberInput
            id="annual-rent-increase"
            value={assumptions?.annual_rent_increase || ""}
            onChange={(value) =>
              handleInputChange("annual_rent_increase", value)
            }
            placeholder="Enter annual rent increase"
          />
        </div>

        <div className="form-group">
          <label htmlFor="monthly-rent-insurance">
            Monthly Rent Insurance
          </label>
          <FormattedNumberInput
            id="monthly-rent-insurance"
            value={assumptions?.monthly_rent_insurance || ""}
            onChange={(value) =>
              handleInputChange("monthly_rent_insurance", value)
            }
            placeholder="Enter monthly rent insurance"
          />
        </div>

        <div className="form-group">
          <label htmlFor="other-monthly-rent-expense">
            Other Monthly Rent Expense
          </label>
          <FormattedNumberInput
            id="other-monthly-rent-expense"
            value={assumptions?.other_monthly_rent_expense || ""}
            onChange={(value) =>
              handleInputChange("other_monthly_rent_expense", value)
            }
            placeholder="Enter other monthly rent expense"
          />
        </div>

        {/* Save Button */}
        <button type="button" onClick={handleSave}>
          Save Assumptions
        </button>

        {/* Disclaimer */}
        <p style={{ fontSize: "12px", color: "gray", marginTop: "20px" }}>
          Note: Please ensure all information is accurate to proceed with the
          analysis.
        </p>
      </form>
    </div>
  );
};

export default BuyerStatusAndRentalInfoForm;
