import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const navigate = useNavigate();
  let { token } = useParams();

  const validatePassword = (password) => {
    const requirements = [
      { test: /.{8,}/, message: "Password must be at least 8 characters long." },
      { test: /[A-Z]/, message: "Password must contain at least one uppercase letter." },
      { test: /[a-z]/, message: "Password must contain at least one lowercase letter." },
      { test: /[0-9]/, message: "Password must contain at least one digit." },
      { test: /[!@#$%^&*(),.?\":{}|<>]/, message: "Password must contain at least one special character." },
    ];

    for (const requirement of requirements) {
      if (!requirement.test.test(password)) {
        return requirement.message;
      }
    }
    return null;
  };

  const handlePasswordReset = async () => {
    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/reset-password/${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          alert(data.msg);
          navigate("/");
        } else {
          alert(data.msg);
        }
      } else {
        const errorText = await response.json();
        console.error("Failed to reset password", errorText);
        alert("Failed to reset password: " + errorText.msg);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while trying to reset the password. Please try again later.");
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center" style={{ height: "100vh" }}>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h2 className="text-center mb-5">Reset Password</h2>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  required
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(""); // Clear error on input change
                    setConfirmPasswordError(""); // Clear confirm password error
                  }}
                />
                {passwordError && <p className="text-danger mt-2">{passwordError}</p>}
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm New Password"
                  required
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordError(""); // Clear error on input change
                  }}
                />
                {confirmPasswordError && <p className="text-danger mt-2">{confirmPasswordError}</p>}
              </div>
              <button
                className="custom-btn btn-primary btn-block"
                onClick={handlePasswordReset}
              >
                Reset Password
              </button>
              <div className="text-center mt-3">
                <small>
                  <button className="btn btn-link" onClick={() => navigate("/")}>
                    Back to login
                  </button>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
