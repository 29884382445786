import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateCurrentAnnualGrossIncome,
  updateCreditStatus,
  updateSavingsBalance,
  updateSavingsInterestRate,
  updateStandardDeduction,
  updateTaxBracket,
  updateFilingStatus,
  updateAnnualMcCTaxCredit,
  updateMaximumMcCTaxCredit,
  updateSelectedAffordability,
} from "../../../../../state/client/clientAffordabilitySlice";

import {
  updateSelectedExpenditure,
  updateSelectedExpenditureField,
} from "../../../../../state/client/clientExpenditureSlice";

import FormattedNumberInput from "../utils/FormattedNumberInput";

import "./AffordabilityAndExpenditureForm.css";
import { getTaxRate } from "../utils/TaxRate";
import TaxBracketModal from "./TaxBracketModal";
import { calculateInterestRate } from "../../PresentMode/utils/CreditUtils";

import { calculateTaxBracket, STANDARD_DEDUCTION_DEFAULTS, getDefaultStandardDeduction, calculateAdjustedTaxableIncome } from "../../../../../utils/calcUtils/editClientCalc";

const AffordabilityAndExpenditureForm = () => {
  const dispatch = useDispatch();

  // Redux State
  const selectedClient = useSelector((state) => state.client.selected_client_id);
  const affordability = useSelector(
    (state) => state.clientAffordability.selected_affordability_details
  );
  const expenditure = useSelector(
    (state) => state.clientExpenditure.selected_expenditure
  );
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);


  useEffect(() => {
    if (selectedClient) {
      dispatch(updateSelectedAffordability(selectedClient));
      dispatch(updateSelectedExpenditure(selectedClient));
    }
  }, [selectedClient, dispatch]);

  const { current_annual_gross_income, filing_status } = affordability;

  useEffect(() => {
    if (current_annual_gross_income && filing_status) {
      const taxRate = calculateTaxBracket(filing_status, current_annual_gross_income);
      dispatch(updateTaxBracket(taxRate));
    }
  }, [current_annual_gross_income, filing_status, dispatch]);

  useEffect(() => {
    if (filing_status) {
      const defaultDeduction = getDefaultStandardDeduction(filing_status);
      dispatch(updateStandardDeduction(defaultDeduction));
    }
  }, [filing_status, dispatch]);

  // Handle Affordability Changes
  const handleAffordabilityChange = (field, value) => {
    switch (field) {
      case "current_annual_gross_income":
        dispatch(updateCurrentAnnualGrossIncome(value));
        break;
      case "credit_status":
        dispatch(updateCreditStatus(value));
        break;
      case "savings_balance":
        dispatch(updateSavingsBalance(value));
        break;
      case "savings_interest_rate":
        dispatch(updateSavingsInterestRate(value));
        break;
      case "standard_deduction":
        dispatch(updateStandardDeduction(value));
        break;
      case "tax_bracket":
        dispatch(updateTaxBracket(value));
        break;
      case "filing_status":
        dispatch(updateFilingStatus(value));
        break;
      case "annual_mcc_tax_credit":
        dispatch(updateAnnualMcCTaxCredit(value));
        break;
      case "maximum_mcc_tax_credit":
        dispatch(updateMaximumMcCTaxCredit(value));
        break;
      default:
        break;
    }
  };

  const handleExpenditureChange = (field, value) => {
    dispatch(updateSelectedExpenditureField({ field, value }));
  };

  const saveAffordability = async () => {
    try {
      if (!selectedClient) {
        console.error("client_id is missing");
        alert("Client ID is required to save affordability data.");
        return;
      }
  
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/client_affordability`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ client_id: selectedClient, ...affordability }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to save affordability data");
      }
  
      console.log("Affordability data saved successfully.");
    } catch (error) {
      console.error("Error saving affordability data:", error);
      alert("Failed to save affordability data. Please try again.");
    }
  };
  

  // Save Expenditure Data
  const saveExpenditure = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/client_expenditure`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ client_id: selectedClient, ...expenditure }),
        }
      );

      if (!response.ok) throw new Error("Failed to save expenditure data");
      console.log("Expenditure data saved successfully.");
    } catch (error) {
      console.error("Error saving expenditure data:", error);
      alert("Failed to save expenditure data. Please try again.");
    }
  };

  // Handle Save
  const handleSave = async () => {
    try {
      await Promise.all([saveAffordability(), saveExpenditure()]);
      alert("Data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
      alert("An error occurred while saving data. Please try again.");
    }
  };

  return (
    <div className="affordability-and-expenditure-form">
      <form>
        <section>
          <h3>Affordability</h3>
          <label>
            Annual Gross Income:
            <FormattedNumberInput
              id="current_annual_gross_income"
              value={affordability?.current_annual_gross_income || ""}
              onChange={(value) =>
                handleAffordabilityChange("current_annual_gross_income", value)
              }
              placeholder="Enter current annual gross income"
            />
          </label>
          <label>
            Filing Status:
            <select
              id="filing_status"
              value={affordability?.filing_status || ""}
              onChange={(e) =>
                handleAffordabilityChange("filing_status", e.target.value)
              }
            >
              <option value="" disabled>
                Select filing status
              </option>
              <option value="single">Single</option>
              <option value="married_filing_jointly">Married Filing Jointly</option>
              <option value="married_filing_separately">Married Filing Separately</option>
              <option value="head_of_household">Head Of Household</option>
            </select>
          </label>
          <label>
            Standard Deduction:
            <FormattedNumberInput
              id="standard_deduction"
              value={
                affordability?.standard_deduction ??
                STANDARD_DEDUCTION_DEFAULTS[affordability?.filing_status]
              }
              onChange={(value) =>
                handleAffordabilityChange("standard_deduction", value)
              }
              placeholder="Enter standard deduction"
            />
          </label>
            <label className="tax-bracket-label">
              Tax Bracket (%):
              <span onClick={handleOpenModal} className="info-icon">
                ℹ️
              </span>
            </label>
            <input
              type="text"
              value={affordability?.tax_bracket || ""}
              readOnly
              className="non-editable-input"
            />
            <TaxBracketModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              filingStatus={affordability?.filing_status}
              grossIncome={parseFloat(affordability?.current_annual_gross_income || 0)} // Pass gross income
              standardDeduction={parseFloat(affordability?.standard_deduction || 0)}    // Pass standard deduction
              propertyTaxWriteOff={2000}                                               // Pass property tax write-off
              income={calculateAdjustedTaxableIncome(
                parseFloat(affordability?.current_annual_gross_income || 0),
                parseFloat(affordability?.standard_deduction || 0),
                2000
              )}
            />

          <label>
            Savings Balance:
            <FormattedNumberInput
              id="savings_balance"
              value={affordability?.savings_balance || ""}
              onChange={(value) =>
                handleAffordabilityChange("savings_balance", value)
              }
              placeholder="Enter savings balance"
            />
          </label>
          <label>
            Savings Interest Rate (%):
            <FormattedNumberInput
              id="savings_interest_rate"
              value={affordability?.savings_interest_rate || ""}
              onChange={(value) =>
                handleAffordabilityChange("savings_interest_rate", value)
              }
              placeholder="Enter savings interest rate"
            />
          </label>
          <label>
            FICO Score:
            <input
              type="text"
              value={affordability.credit_status || ""}
              onChange={(e) =>
                handleAffordabilityChange("credit_status", e.target.value)
              }
            />
          </label>

          {/* <label>
            Annual MCC Tax Credit:
            <FormattedNumberInput
              id="annual_mcc_tax_credit"
              value={affordability?.annual_mcc_tax_credit || ""}
              onChange={(value) =>
                handleAffordabilityChange("annual_mcc_tax_credit", value)
              }
              placeholder="Enter annual MCC tax credit"
            />
          </label>
          <label>
            Maximum MCC Tax Credit:
            <FormattedNumberInput
              id="maximum_mcc_tax_credit"
              value={affordability?.maximum_mcc_tax_credit || ""}
              onChange={(value) =>
                handleAffordabilityChange("maximum_mcc_tax_credit", value)
              }
              placeholder="Enter maximum MCC tax credit"
            />
          </label> */}
        </section>
  
        <section>
          <h3>Expenditure</h3>
          <label>
            Maximum Mortgage Payment:
            <FormattedNumberInput
              id="maximum_mortgage_payment"
              value={expenditure?.maximum_mortgage_payment || ""}
              onChange={(value) =>
                handleExpenditureChange("maximum_mortgage_payment", value)
              }
              placeholder="Enter maximum mortgage payment"
            />
          </label>
          <label>
            Ideal Mortgage Payment:
            <FormattedNumberInput
              id="ideal_mortgage_payment"
              value={expenditure?.ideal_mortgage_payment || ""}
              onChange={(value) =>
                handleExpenditureChange("ideal_mortgage_payment", value)
              }
              placeholder="Enter ideal mortgage payment"
            />
          </label>
          <label>
            Price Range Low:
            <FormattedNumberInput
              id="price_range_low"
              value={expenditure?.price_range_low || ""}
              onChange={(value) =>
                handleExpenditureChange("price_range_low", value)
              }
              placeholder="Enter price range low"
            />
          </label>
          <label>
            Price Range High:
            <FormattedNumberInput
              id="price_range_high"
              value={expenditure?.price_range_high || ""}
              onChange={(value) =>
                handleExpenditureChange("price_range_high", value)
              }
              placeholder="Enter price range high"
            />
          </label>
        </section>

  

        <button type="button" onClick={handleSave}>
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default AffordabilityAndExpenditureForm;