import { createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false, // Default loading state is false
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload; // Set loading state to true or false
    },
  },
});

export const { setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
