import { createSlice } from '@reduxjs/toolkit'

// Define the initial state
const initialState = {
  user_id: null,
  username: '',
  password: '',
  email_address: '',
  is_verified: false,
  verification_token: null,
  is_approved: false,
  reset_token_expiration: null,
  reset_token: null,
  first_name: '', // Add missing fields if required
  last_name: '',
  date_updated: null,
  filing_status: '',
  income: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserId: (state, action) => {
      state.user_id = action.payload;
      console.log("User ID updated:", state.user_id);
    },
    updateUsername: (state, action) => {
      state.username = action.payload;
    },
    updateFirstName: (state, action) => {
      state.first_name = action.payload;
    },
    updateLastName: (state, action) => {
      state.last_name = action.payload;
    },
    updateEmailAddress: (state, action) => {
      state.email_address = action.payload;
    },
    updateDateUpdated: (state, action) => {
      state.date_updated = action.payload;
    },
    updateFilingStatus: (state, action) => {
      state.filing_status = action.payload;
    },
    updateIncome: (state, action) => {
      state.income = action.payload;
    },
    reset: () => initialState, // Reset to initial state
  },
});

// Action creators are generated for each case reducer function
export const { 
  updateUserId, 
  updateFirstName, 
  updateLastName, 
  updateEmailAddress, 
  updateDateUpdated, 
  updateFilingStatus, 
  updateIncome, 
  updateUsername, 
  reset,
} = userSlice.actions;

export default userSlice.reducer;
