import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientContext } from '../../ClientContext';
import AddClientModal from './AddClientModal';
import './Clients.css';
import { format } from "date-fns";
import { fetchClients } from '../../../apiFetch/fetchClients';

import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useSelector, useDispatch } from 'react-redux';
import { 
  setClients, 
  updateSelectedClient, 
  addClient,
  addNewClient
} from '../../../state/client/clientSlice';

const Clients = () => {
  const homes = useSelector(state => state.home.homes); // homes is an object

  // Convert homes object to an array
  const homesArray = Object.values(homes);

  const clients = useSelector(state => state.client.clients);
  const selectedClientDetails = useSelector(state => state.client.selected_client_details);
  const selectedClient = useSelector(state => state.client.selected_client_id);

  // const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState('');
  const [bannerMessage, setBannerMessage] = useState(''); // State for the banner message
  const [showBanner, setShowBanner] = useState(false); // State to show/hide the banner
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalClient, setDeleteModalClient] = useState(null);

  const [isLoading, setIsLoading] = useState(false); // Add loading state

  // const [selectedClient, setSelectedClient] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const navigate = useNavigate();
  // const { setSelectedClient: setGlobalSelectedClient } = useContext(ClientContext);

  const dispatch = useDispatch();

  const [sortConfig, setSortConfig] = useState({ key: 'updated_at', direction: 'descending' });

  const sortedClients = Object.values(clients).sort((a, b) => {
    let aValue = a[sortConfig.key];
    let bValue = b[sortConfig.key];
    // Handle sorting for date fields
    if (sortConfig.key === "updated_at") {
      aValue = aValue ? new Date(aValue).getTime() : 0; // Convert to timestamp for comparison
      bValue = bValue ? new Date(bValue).getTime() : 0; // Convert to timestamp for comparison
    }
  
    // Log the comparison result
    const comparisonResult =
      aValue < bValue
        ? sortConfig.direction === "ascending"
          ? -1
          : 1
        : aValue > bValue
        ? sortConfig.direction === "ascending"
          ? 1
          : -1
        : 0;
    return comparisonResult;
  });
  

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  // useEffect(() => {
  //   const fetchClients = async () => {
  //     const token = localStorage.getItem('token');
  //     if (!token) {
  //       setMessage('No token found, please log in.');
  //       return;
  //     }
  
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_clients`, {
  //         method: 'GET',
  //         headers: {
  //           'Authorization': `Bearer ${token}`,
  //         }
  //       });
  
  //       const result = await response.json();
  //       if (response.ok) {
  //         dispatch(setClients(result)); // Update Redux state
  //       } else {
  //         setMessage(result.status);
  //       }
  //     } catch (error) {
  //       setMessage(`An error occurred: ${error.message}`);
  //     }
  //   };
  
  //   // Check if clients object is empty
  //   if (Object.keys(clients).length === 0) {
  //     fetchClients();
  //   }
  // }, [dispatch, clients]);

  useEffect(() => {
    const loadClients = async () => {
      try {
        // Use the imported fetchClients function
        await fetchClients((fetchedClients) => {
          dispatch(setClients(fetchedClients)); // Update Redux state
        });
      } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
      }
    };
  
    // Check if clients object is empty
    if (Object.keys(clients).length === 0) {
      loadClients();
    }
  }, [dispatch, clients]);
  

  const handleAddClient = async (client) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/add_clients`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(client),
      });
  
      const result = await response.json();
      console.log("RESULT FROM ADD CLIENT: ", result);
      if (response.ok) {
        dispatch(addNewClient(result)); 
        showBannerWithMessage('Client added successfully.');
      } else {
        setMessage(result.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };
  

  const handleEditClient = async (client) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_clients/${client.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(client)
      });
  
      if (response.ok) {
        const result = await response.json();
        setClients(clients.map(c => c.id === client.id ? result : c));
        showBannerWithMessage('Client updated successfully.');
      } else {
        const result = await response.json();
        setMessage(result.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };
   
  const toggleDropdown = (clientId) => {
    setDropdownOpen((prev) => (prev === clientId ? null : clientId));
  };
  
  const handleDeleteClient = async (clientId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/delete_clients/${clientId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
  
      const result = await response.json();
      if (response.ok) {
        dispatch(setClients(Object.values(clients).filter(client => client.client_id !== clientId))); // Remove client from Redux state
        showBannerWithMessage('Client deleted successfully.');
        setShowDeleteModal(false);
        dispatch(updateSelectedClient(null));
      } else {
        setMessage(result.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  const handleClientClick = (client) => {
    dispatch(updateSelectedClient(client.client_id)); // Pass client_id, not the full client object
    navigate('/ClientLayout/WelcomePage', { state: { clientId: client.client_id } }); // Use client_id here
  };

  

  const handleCancelDelete = () => {
    setShowDeleteModal(false); // Close the delete modal
    setDeleteModalClient(null); // Reset the delete modal client
  };


  const handleSave = async (client) => {
    setIsLoading(true); // Start loading
    try {
      if (client.id) {
        await handleEditClient(client); // Wait for edit to complete
      } else {
        await handleAddClient(client); // Wait for add to complete
        navigate('/ClientLayout/WelcomePage');
      }
      setShowAddModal(false);
      setShowEditModal(false);
    } catch (error) {
      console.error('Error saving client:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }; // Only year, month, and day
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  
  const openAddModal = () => {
    dispatch(updateSelectedClient(null));
    setShowAddModal(true);
  };

  const openEditModal = (client) => {
    dispatch(updateSelectedClient(client.client_id)); // Pass client_id to Redux
    setShowEditModal(true);
  };

  const showBannerWithMessage = (message) => {
    setBannerMessage(message);
    setShowBanner(true);
    setTimeout(() => {
      setShowBanner(false);
    }, 3000); // Hide the banner after 3 seconds
  };

  return (
    <Container sx={{ mt: 4 }}>
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <CircularProgress />
        </Box>
      )}
      <Typography
        variant="h4"
        align="center"
        sx={{ mb: 4, color: 'secondary.main' }} // Adjust color to your theme or specific value
      >
        Clients
      </Typography>
      {showBanner && (
        <Box
          sx={{
            backgroundColor: 'success.main',
            p: 2,
            mb: 2,
            borderRadius: 1,
            textAlign: 'center',
          }}
        >
          <Typography variant="body1" color="white">
            {bannerMessage}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <TextField
          fullWidth
          label="Search clients..."
          variant="outlined"
          size="small" // Adjusted size
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            flexGrow: 1,
            mr: 2,
            '.MuiOutlinedInput-root': {
              height: '40px', // Consistent height with the button
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openAddModal}
          sx={{
            minWidth: '150px',
            height: '40px', // Consistent height with the TextField
          }}
        >
          Add Client
        </Button>
      </Box>
      <Box>
        <div className="client-list">
          <div className="client-item-header">
            <p onClick={() => handleSort('first_name')}>
              First Name{' '}
              {sortConfig.key === 'first_name'
                ? sortConfig.direction === 'ascending'
                  ? '↑'
                  : '↓'
                : '↕'}
            </p>
            <p onClick={() => handleSort('last_name')}>
              Last Name{' '}
              {sortConfig.key === 'last_name'
                ? sortConfig.direction === 'ascending'
                  ? '↑'
                  : '↓'
                : '↕'}
            </p>
            <p>Email</p>
            <p onClick={() => handleSort('updated_at')}>
              Last Updated{' '}
              {sortConfig.key === 'updated_at'
                ? sortConfig.direction === 'ascending'
                  ? '↑'
                  : '↓'
                : '↕'}
            </p>
            <p></p>
          </div>
          {sortedClients
            .filter((client) => {
              const firstName = client.first_name || '';
              const lastName = client.last_name || '';
              const email = client.email || '';
              return (
                firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                email.toLowerCase().includes(searchTerm.toLowerCase())
              );
            })
            .map((client) => (
              <div key={client.client_id} className="client-item">
                <div className="client-info" onClick={() => handleClientClick(client)}>
                  <p>{client.first_name || 'N/A'}</p>
                  <p>{client.last_name || 'N/A'}</p>
                  <p>{client.email || 'N/A'}</p>
                  
                  <p>
                    {client.updated_at
                      ? formatDate(client.updated_at)
                      : 'N/A'}
                  </p>
                  
                </div>
                <div
                  className="three-dot-menu"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(client.client_id);
                  }}
                >
                  <span className="three-dot-span">⋮</span>
                  {dropdownOpen === client.client_id && (
                    <div
                      className="three-dot-dropdown"
                      onMouseLeave={() => setDropdownOpen(null)}
                    >
                      <div
                        className="three-dot-dropdown-item"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(updateSelectedClient(client)); // Update the selected client in Redux
                          setDropdownOpen(null); // Close the dropdown
                          navigate('/ClientLayout/EditClient'); // Navigate to the EditClient route
                        }}
                      >
                        Edit
                      </div>
                      <div
                        className="three-dot-dropdown-item"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteModalClient(client);
                          setShowDeleteModal(true);
                          setDropdownOpen(null);
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </Box>
      <AddClientModal
        show={showAddModal || showEditModal}
        onClose={() => {
          setShowAddModal(false);
          setShowEditModal(false);
        }}
        onSave={handleSave}
        initialData={selectedClient || { first_name: '', last_name: '', email: '' }}
      />
      {showDeleteModal && (
        <div className="modal-overlay" onClick={() => setShowDeleteModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this client?</p>
            <div className="modal-buttons">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  if (!deleteModalClient || !deleteModalClient.client_id) {
                    console.error('No client selected for deletion.');
                    return;
                  }
                  handleDeleteClient(deleteModalClient.client_id);
                }}
                sx={{ mr: 2 }}
              >
                Delete
              </Button>
              <Button variant="outlined" onClick={handleCancelDelete}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );   
};

export default Clients;
