import React from 'react';
import './VerificationSuccess.css';
import { useNavigate } from 'react-router-dom';

const VerificationSuccess = ({ openLogin }) => {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate('/'); // Navigate to the home page
    openLogin(); // Open the login modal
  };

  return (
    <div className="verification-container">
      <div className="verification-card">
        <h1>🎉 Success!</h1>
        <p>Your email has been successfully verified.</p>
        <button className="verification-btn" onClick={handleGoToLogin}>
          Go to Login
        </button>
      </div>
    </div>
  );
};

export default VerificationSuccess;
