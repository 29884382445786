import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { TextField, Button, Container, Box, Typography, InputAdornment } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import { useDispatch } from 'react-redux'; // Import useDispatch
import { updateUserId } from '../../state/user/userSlice'; // Import Redux action to update user state

const Login = ({ onClose, openSignup }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch(); // Initialize Redux dispatch

  const navigate = useNavigate(); // Hook for navigation

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    const loginData = { username, password };

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginData),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.is_approved) {
          localStorage.setItem('token', data.token); // Store the token
          navigate('/HeaderLayout/Clients'); // Navigate to Clients page
          onClose(); // Close the modal
          dispatch(updateUserId({ user_id: data.user_id })); // Dispatch action to set user_id
        } else {
          setLoginError('Your account is awaiting admin approval.');
        }
      } else {
        const text = await response.text();
        setLoginError(text);
      }
    } catch (error) {
      console.error('Error:', error);
      setLoginError('An error occurred during login.');
    } finally {
      setLoading(false);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <form onSubmit={handleLogin}>
          <Container maxWidth="xs">
            <Box sx={{ textAlign: 'center', mb: 3 }}>
              <Typography variant="h5">Login</Typography>
            </Box>
            <TextField
              fullWidth
              label="Username"
              placeholder="Enter your username"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              placeholder="Enter your password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
            />
            {loginError && <Typography color="error" sx={{ mt: 1 }}>{loginError}</Typography>}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              disabled={loading}
            >
              {loading ? 'Logging In...' : 'Login'}
            </Button>
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don't have an account?{' '}
              <Link to="#" onClick={() => { onClose(); openSignup(); }}>
                Sign Up
              </Link>
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              <Link to="/ForgotPassword" onClick={onClose}>
                Forgot password?
              </Link>
            </Typography>
          </Container>
        </form>
      </div>
    </div>
  );
};

export default Login;
