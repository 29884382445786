import { createTheme } from '@mui/material/styles';

const shadows = [
  'none',
  '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
  '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  '0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
  // Add more shadow definitions up to 24...
  '0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
  '0px 5px 5px rgba(0, 0, 0, 0.2), 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12)',
  '0px 8px 10px rgba(0, 0, 0, 0.2), 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12)',
  // Continue to fill up to 24
  ...Array(24).fill('0px 8px 24px rgba(0, 0, 0, 0.14)'), // Fallback for missing ones
];

const theme = createTheme({
  palette: {
    primary: {
      main: '#D95D39', // Main primary color
      contrastText: '#ffffff', // Text color for primary elements
    },
    secondary: {
      main: '#2c3e50', // Secondary color
      contrastText: '#2c3e50', // Text on secondary
    },
    background: {
      default: '#2c3e50', // Main background color
      paper: '#f5f5f5', // Paper or card background
    },
    text: {
      primary: '#555555', // Default text color (now darker for better contrast)
      secondary: '#555555', // Secondary text color
    },
    error: {
      main: '#e53935', // Error color
    },
  },
  typography: {
    fontFamily: "'Avenir', 'Roboto', 'Arial', sans-serif", // Font family to match CSS
    h1: {
      color: '#ffffff', // Headings text color (darker for better readability)
      fontWeight: 700,
    },
    h2: {
      color: '#ffffff',
    },
    body1: {
      color: '#555555', // Body text color (slightly darker for readability)
    },
    body2: {
      color: '#55555', // Body text color for secondary content
    },
    button: {
      textTransform: 'none', // Prevent uppercase transformation
      color: '#ffffff',
    },
  },
  shape: {
    borderRadius: 4, // Match border-radius from CSS
  },
  shadows, // Include shadows array
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#D95D39', // Button default background
          color: '#ffffff', // Button text color
          '&:hover': {
            backgroundColor: '#555555', // Button hover color
          },
          borderRadius: '5px', // Button border radius
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(44, 62, 80, 0.9)', // Navbar background
        },
      },
    },
  },
});

export default theme;
