import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import './Faq.css';

const faqData = [
  {
    category: "General Questions",
    items: [
      {
        question: "What is the purpose of this tool?",
        answer: "This tool helps users compare the long-term wealth-building potential of using their savings for a real estate purchase versus investing in the stock market. It provides a side-by-side analysis of factors like appreciation, cash flow, and expenses over time.",
      },
      {
        question: "Who can benefit from using this tool?",
        answer: "First-time homebuyers, real estate investors, and individuals deciding whether to invest in the stock market or purchase property.",
      },
      {
        question: "What time frames are analyzed in this tool?",
        answer: "The tool evaluates wealth growth over 5, 10, 20, and 30 years.",
      },
    ],
  },
  {
    category: "Real Estate Questions",
    items: [
      {
        question: "What assumptions are made for the real estate scenario?",
        answer: [
          "Minimum down payment (typically 5% of property value for first-time homebuyers).",
          "Property appreciation rate (default is 3%).",
          "Typical mortgage terms, including a 30-year fixed-rate loan and a set mortgage interest rate.",
          "Property expenses like taxes, insurance, and maintenance.",
        ],
      },
      {
        question: "How does the tool handle rental savings?",
        answer: "If the user is buying a primary residence, the tool calculates the savings from replacing rent payments with a mortgage.",
      },
      {
        question: "What happens if I don’t have a specific property in mind?",
        answer: "The tool assumes a maximum leverage scenario (95% loan-to-value ratio) based on your available savings.",
      },
      {
        question: "Does the tool account for rental income if I buy an investment property?",
        answer: "This version of the tool is focused on primary residences, but it can be modified to include rental income for investment properties.",
      },
    ],
  },
  {
    category: "Stock Market Questions",
    items: [
      {
        question: "What assumptions are made for the stock market scenario?",
        answer: [
          "Annual stock market return: 7% (based on historical S&P 500 performance).",
          "Dividend yield: 2% (reinvested).",
          "Investment fees: 1% (management or brokerage fees).",
        ],
      },
      {
        question: "Does the tool factor in stock market volatility?",
        answer: "No, the tool assumes average annual returns and does not simulate market downturns or fluctuations.",
      },
      {
        question: "Can I adjust the dividend reinvestment or fees?",
        answer: "Yes, these parameters are customizable in the tool’s input section.",
      },
    ],
  },
  {
    category: "Comparison and Results",
    items: [
      {
        question: "What does the tool compare?",
        answer: "The tool compares net wealth growth over time, including factors like property appreciation, equity build-up, rent savings, portfolio value, dividend reinvestment, and expenses.",
      },
      {
        question: "Does the tool account for inflation?",
        answer: "Currently, the tool does not adjust for inflation, but it can be included in future iterations.",
      },
      {
        question: "How are tax advantages calculated?",
        answer: "Tax advantages such as mortgage interest deductions and reduced capital gains tax rates are considered in real estate scenarios. For stocks, the tool accounts for dividend taxes and long-term capital gains taxes.",
      },
      {
        question: "What if I sell the property or stock portfolio early?",
        answer: "The tool focuses on long-term ownership (up to 30 years). However, we can modify it to simulate early sales and associated costs.",
      },
      {
        question: "How is net value defined?",
        answer: [
          "Real Estate: Net value = Equity + Rent Savings - Property Expenses.",
          "Stocks: Net value = Portfolio Value (including reinvested dividends).",
        ],
      },
    ],
  },
  {
    category: "Customizing the Tool",
    items: [
      {
        question: "Can I change input parameters like mortgage rates or stock market returns?",
        answer: "Yes, the tool is fully customizable, and you can adjust key assumptions to fit your unique situation.",
      },
      {
        question: "What if my savings increase over time?",
        answer: "The tool assumes a one-time investment at the start. However, it can be adjusted to include periodic contributions.",
      },
      {
        question: "Can I add other factors, like inflation or alternative investment strategies?",
        answer: "Yes, the tool can be expanded to include additional variables, such as inflation-adjusted returns or different types of investments (e.g., mutual funds, ETFs).",
      },
    ],
  },
  {
    category: "Practical Use",
    items: [
      {
        question: "How accurate are the projections?",
        answer: "The tool uses reasonable assumptions based on historical data, but actual results may vary due to market fluctuations, changes in property values, or unexpected expenses.",
      },
      {
        question: "How do I interpret the results?",
        answer: "Use the visual comparison and detailed tables to understand which investment option better aligns with your financial goals over time.",
      },
      {
        question: "Can I export the results?",
        answer: "Yes, the results can be exported to Excel for further analysis.",
      },
    ],
  },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-page">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      {faqData.map((category, catIndex) => (
        <div key={catIndex} className="faq-category">
          {/* Orange category title */}
          <h2 className="faq-category-title">{category.category}</h2>
          <div className="faq-list">
            {category.items.map((faq, index) => (
              <div key={index} className="faq-item">
                <button
                  className={`faq-question ${
                    activeIndex === `${catIndex}-${index}` ? 'active' : ''
                  }`}
                  onClick={() => toggleFaq(`${catIndex}-${index}`)}
                  aria-expanded={activeIndex === `${catIndex}-${index}`}
                  aria-controls={`faq-answer-${catIndex}-${index}`}
                >
                  {faq.question}
                  <ChevronDown
                    className={`faq-icon ${
                      activeIndex === `${catIndex}-${index}` ? 'rotate' : ''
                    }`}
                  />
                </button>
                <div
                  id={`faq-answer-${catIndex}-${index}`}
                  className={`faq-answer ${
                    activeIndex === `${catIndex}-${index}` ? 'show' : ''
                  }`}
                >
                  {/* Render as bullet points if the answer is an array */}
                  {Array.isArray(faq.answer) ? (
                    <ul>
                      {faq.answer.map((point, i) => (
                        <li key={i}>{point}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{faq.answer}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};  

export default FAQ;

