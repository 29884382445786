// src/utils/fetchClientInfo.js
export const fetchClientInfo = async (clientId, setClientInfo) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.log('No token found, please log in.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_client_info/${clientId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const result = await response.json();

    if (response.ok) {
      console.log('Fetched Client Info:', result); // Log fetched client info
      setClientInfo(result); // Pass the client data to the callback function
    } else {
      console.error(`Error fetching client info: ${result.status}`);
      setClientInfo(null); // Clear client info on error
    }
  } catch (error) {
    console.error(`An error occurred while fetching client info: ${error.message}`);
    setClientInfo(null); // Clear client info on error
  }
};
