// utils/CreditUtils.js

// Define FICO score adjustments
const FICO_ADJUSTMENTS = [
    { range: [800, Infinity], adjustment: [-0.25, -0.50] }, // Exceptional
    { range: [740, 799], adjustment: [-0.10, -0.25] },     // Very Good
    { range: [670, 739], adjustment: [0, 0] },             // Good
    { range: [580, 669], adjustment: [0.25, 0.75] },       // Fair
    { range: [300, 579], adjustment: [1.00, Infinity] },   // Poor
  ];
  
  // Define LTV adjustments
  const LTV_ADJUSTMENTS = [
    { range: [0, 60], adjustment: [-0.25, -0.50] },
    { range: [61, 70], adjustment: [-0.10, -0.25] },
    { range: [71, 80], adjustment: [0, 0] },
    { range: [81, 85], adjustment: [0.25, 0.25] },
    { range: [86, 90], adjustment: [0.50, 0.50] },
    { range: [91, 95], adjustment: [0.75, 0.75] },
    { range: [96, Infinity], adjustment: [1.00, Infinity] },
  ];
  
  // Base rates for loans
  const BASE_RATES = {
    "30-year": 6.98,
    "15-year": 6.39,
  };
  
  // Calculate FICO adjustment
  export const getFICOAdjustment = (ficoScore) => {
    for (const { range, adjustment } of FICO_ADJUSTMENTS) {
      if (ficoScore >= range[0] && ficoScore <= range[1]) {
        return adjustment[0]; // Use the first value in the range
      }
    }
    return 0; // Default to 0 if no match
  };
  
  // Calculate LTV adjustment
  export const getLTVAdjustment = (loanAmount, homeValue) => {
    const ltv = (loanAmount / homeValue) * 100;
    for (const { range, adjustment } of LTV_ADJUSTMENTS) {
      if (ltv >= range[0] && ltv <= range[1]) {
        return adjustment[0];
      }
    }
    return 0; // Default to 0 if no match
  };
  
  // Calculate interest rate
  export const calculateInterestRate = ({
    loanTerm = "30-year",
    ficoScore,
    loanAmount,
    homeValue,
    marketRiskPremium = 0,
    loanTermAdjustment = 0,
  }) => {
    const baseRate = BASE_RATES[loanTerm];
    const ficoAdjustment = getFICOAdjustment(ficoScore);
    const ltvAdjustment = getLTVAdjustment(loanAmount, homeValue);
  
    return (
      baseRate +
      ficoAdjustment +
      ltvAdjustment +
      marketRiskPremium +
      loanTermAdjustment
    );
  };
  