export const fetchUserContact = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_contact_info`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user contact info');
    }

    return await response.json(); // Expect single object
  } catch (error) {
    console.error('Error in fetchUserContact:', error);
    throw error;
  }
};
