import React, { useEffect } from "react";
import "./GoalsForm.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setGoals,
  updateSelectedGoalField,
  selectGoals,
} from "../../../../../state/client/clientGoalsSlice";
import FormattedNumberInput from "../utils/FormattedNumberInput";

const GoalsForm = () => {
  const dispatch = useDispatch();

  // Get the selected client ID and goals from Redux
  const selectedClient = useSelector((state) => state.client.selected_client_id);
  const clientGoals = useSelector((state) => state.clientGoals.selected_goals);
  const allGoals = useSelector((state) => state.clientGoals.goals);


  // Handle changes to goal fields
  const handleGoalChange = (goalName, value) => {
    dispatch(updateSelectedGoalField({ field: goalName, value }));
  };

  const handleSaveGoals = async () => {
    if (selectedClient === -1) {
      alert("No client selected. Please select a client before saving goals.");
      return;
    }
    // Add `client_id` to the `clientGoals` before saving
    const updatedGoals = { ...clientGoals, client_id: selectedClient };
  
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/client_goals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedGoals),
        }
      );
  
      if (response.ok) {
        const result = await response.json();
        alert("Goals saved successfully!");
      } else {
        console.error("Failed to save goals");
        alert("Failed to save goals. Please try again.");
      }
    } catch (error) {
      console.error("Error saving goals:", error);
      alert("An error occurred while saving goals. Please try again.");
    }
  };  

  return (
    <div className="goals-form">
      <h2>Goals</h2>
      <form>
        {/* Support Retirement */}
        <div>
          <label>
            <input
              type="checkbox"
              checked={clientGoals.support_retirement || false}
              onChange={(e) =>
                handleGoalChange("support_retirement", e.target.checked)
              }
            />
            Support Retirement
          </label>
            <p>
              If this option is selected, emphasis will be placed on demonstrating how
              their initial investment will turn into savings over time. Age-based
              calculations will help make these projections clear.
            </p>
          
        </div>
        <br />

        {/* Pay Off Debt */}
        <div>
          <label>
            <input
              type="checkbox"
              checked={clientGoals.pay_off_debt || false}
              onChange={(e) =>
                handleGoalChange("pay_off_debt", e.target.checked)
              }
            />
            Pay Off Debt
          </label>

            <p>
              This option will focus on showing how equity in the home can be used to pay
              off high-interest debt, along with showing tax benefits and savings over the
              years.
            </p>
  
        </div>
        <br />

        {/* Lower Interest Rate */}
        <div>
          <label>
            <input
              type="checkbox"
              checked={clientGoals.lower_interest_rate || false}
              onChange={(e) =>
                handleGoalChange("lower_interest_rate", e.target.checked)
              }
            />
            Lower Interest Rate
          </label>
      
            <p>
              This option will calculate potential savings by lowering the interest rate.
              Current and future payments will be displayed.
            </p>
    
        </div>
        <br />

        {/* Purchase New Home */}
        <div>
          <label>
            <input
              type="checkbox"
              checked={clientGoals.purchase_new_home || false}
              onChange={(e) =>
                handleGoalChange("purchase_new_home", e.target.checked)
              }
            />
            Purchase New Home
          </label>
      
            <p>
              This option will display a rent vs. own calculator to help the client
              evaluate the financial implications of purchasing a new home.
            </p>
          
        </div>
        <br />

        {/* Reduce Payment */}
        <div>
          <label>
            <input
              type="checkbox"
              checked={clientGoals.reduce_payment || false}
              onChange={(e) =>
                handleGoalChange("reduce_payment", e.target.checked)
              }
            />
            Reduce Payment
          </label>
            <p>
              This will focus on lowering the payment through refinancing or other
              financial strategies.
            </p>
        
        </div>
        <br />

        {/* Tap Into Equity */}
        <div>
          <label>
            <input
              type="checkbox"
              checked={clientGoals.tap_into_equity || false}
              onChange={(e) =>
                handleGoalChange("tap_into_equity", e.target.checked)
              }
            />
            Tap Into Equity
          </label>
            <p>
              This option will calculate the potential line of equity at specific intervals
              and show what will be available to the client.
            </p>
    
        </div>
        <br />

        {/* Save Button */}
        <button type="button" onClick={handleSaveGoals}>
          Save Goals
        </button>

      </form>
    </div>
  );
};

export default GoalsForm;
