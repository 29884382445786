// src/apiFetch/fetchClientContact.js
export const fetchClientContact = async (clientId, setClientContact) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found, please log in.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_client_contact/${clientId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      const result = await response.json();
  
      if (response.ok) {
        console.log('Fetched Client Contact:', result);
        setClientContact(result); // Pass the client contact data to the callback function
      } else {
        console.warn(`Error fetching client contact: ${result.status}`);
        setClientContact(null); // Pass null if the contact is not found
      }
    } catch (error) {
      console.error(`An error occurred while fetching client contact: ${error.message}`);
      setClientContact(null); // Pass null if an error occurs
    }
  };
  