import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setContactInfo, updateContactDetails } from '../../../state/user/userContactSlice';
import { fetchUserContact } from '../../../apiFetch/fetchUserContact';
import './Contact.css';

const Contact = () => {
  const dispatch = useDispatch();
  const { contactInfo, selected_contact_details, status, error } = useSelector(
    (state) => state.userContact
  );
  const [localContactDetails, setLocalContactDetails] = useState({ ...selected_contact_details });
  const [message, setMessage] = useState('');

  // Fetch contact info on component mount
  useEffect(() => {
    const fetchInitialContactInfo = async () => {
      try {
        const fetchedData = await fetchUserContact();
        // Assume fetchedData is a single object from the backend
        dispatch(setContactInfo(fetchedData)); // Dispatch single object
      } catch (error) {
        console.error('Error fetching contact info:', error);
        setMessage(`Error: ${error.message}`);
      }
    };
  
    if (status === 'idle' || Object.keys(contactInfo).length === 0) {
      fetchInitialContactInfo();
    }
  }, [dispatch, status, contactInfo]);
  
  
  

  // Update local state when Redux state changes
  useEffect(() => {
    if (Object.keys(selected_contact_details).length > 0) {
      setLocalContactDetails({ ...selected_contact_details });
    }
  }, [selected_contact_details]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalContactDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setMessage('');
    const token = localStorage.getItem('token');

    if (!token) {
      setMessage('Authorization token is missing. Please log in.');
      return;
    }

    try {
      // Dispatch the updated contact details to the Redux store
      dispatch(updateContactDetails(localContactDetails));

      // Send the updated contact details to the backend
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_contact_info`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(localContactDetails),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setMessage(`Error: ${errorData.message || 'Failed to save contact details.'}`);
      } else {
        setMessage('Contact details successfully saved!');
      }
    } catch (err) {
      setMessage(`An error occurred: ${err.message}`);
    }
  };

  if (status === 'loading') return <p>Loading...</p>;
  if (status === 'failed') return <p>{error}</p>;

  return (
    <div className="contact-container">
      <h1>Contact</h1>
      {message && <p className={`message ${message.startsWith('Error') ? 'error' : 'success'}`}>{message}</p>}
      <form>
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            name="first_name"
            value={localContactDetails.first_name || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            name="last_name"
            value={localContactDetails.last_name || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Email Address / Login Name</label>
          <input
            type="email"
            name="email"
            value={localContactDetails.email || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Company Name</label>
          <input
            type="text"
            name="company_name"
            value={localContactDetails.company_name || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Website URL</label>
          <input
            type="text"
            name="website_url"
            value={localContactDetails.website_url || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Start Now URL</label>
          <input
            type="text"
            name="start_now_url"
            value={localContactDetails.start_now_url || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input
            type="text"
            name="phone_number"
            value={localContactDetails.phone_number || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Cell Phone</label>
          <input
            type="text"
            name="cell_phone"
            value={localContactDetails.cell_phone || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Fax</label>
          <input
            type="text"
            name="fax"
            value={localContactDetails.fax || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Business Street Address</label>
          <input
            type="text"
            name="business_street_address"
            value={localContactDetails.business_street_address || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            name="city"
            value={localContactDetails.city || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>State</label>
          <input
            type="text"
            name="state"
            value={localContactDetails.state || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Zip</label>
          <input
            type="text"
            name="zip"
            value={localContactDetails.zip || ''}
            onChange={handleChange}
          />
        </div>
        <button type="button" className="save-button" onClick={handleSave}>
          Save
        </button>
      </form>
    </div>
  );
};

export default Contact;
