import React from "react";
import PropTypes from "prop-types";
import { ResponsiveLine } from "@nivo/line";

const RentOwnSP500Chart = ({ home, annualAppreciationRate, assumptions }) => {
  if (!home) {
    return <p>No home data available.</p>;
  }

  // Extract necessary values from home data
  const {
    home_price: homePriceRaw,
    interest_rate: interestRateRaw,
    down_payment: downPaymentRaw,
    term_months: termMonthsRaw,
  } = home;

  const {
    current_rent,
    annual_rent_increase,
  } = assumptions;

  const homePrice = parseFloat(homePriceRaw) || 0;
  const rentAmount = current_rent || 0;
  const rentIncreaseRate = annual_rent_increase || 0.03; // Default 3% rent increase
  const sp500GrowthRate = 0.10; // 10% annual increase for S&P 500
  const years = 30;
  const downPayment = parseFloat(downPaymentRaw) || 0;
  const interestRate = parseFloat(interestRateRaw) || 0;
  const termMonths = parseInt(termMonthsRaw, 10) || 360; // Default to 30 years

  const calculateMonthlyPayment = (loanAmount, annualInterestRate, termMonths) => {
    const monthlyRate = annualInterestRate / 12 / 100;
    return (
      (loanAmount * monthlyRate) /
      (1 - Math.pow(1 + monthlyRate, -termMonths))
    );
  };
  const loanAmount = homePrice - downPayment;

  const calculateOutstandingLoanBalance = (loanAmount, annualInterestRate, termMonths, elapsedMonths) => {
    const monthlyRate = annualInterestRate / 12 / 100;
    if (elapsedMonths >= termMonths) return 0; // Loan fully paid off

    const monthlyPayment = calculateMonthlyPayment(loanAmount, annualInterestRate, termMonths);

    return (
      loanAmount * Math.pow(1 + monthlyRate, elapsedMonths) -
      monthlyPayment * ((Math.pow(1 + monthlyRate, elapsedMonths) - 1) / monthlyRate)
    );
  };

  const ownNetWealthValues = Array.from({ length: years }, (_, i) => {
    const elapsedMonths = (i + 1) * 12;
    const homeValue = homePrice * Math.pow(1 + annualAppreciationRate, i + 1);
    const outstandingLoanBalance = calculateOutstandingLoanBalance(
      loanAmount,
      interestRate,
      termMonths,
      elapsedMonths
    );
    return {
      x: i + 1,
      y: parseFloat((homeValue - outstandingLoanBalance).toFixed(2)), // Net Wealth formatted
    };
  });
  
  const sp500Values = Array.from({ length: years }, (_, i) => ({
    x: i + 1,
    y: parseFloat((downPayment * Math.pow(1 + sp500GrowthRate, i + 1)).toFixed(2)),
  }));

  const calculateFutureRentExpenses = (rentAmount, rentIncreaseRate, years) => {
    return Array.from({ length: years }, (_, i) => ({
      x: i + 1,
      y: parseFloat((12 * rentAmount * ((Math.pow(1 + rentIncreaseRate, i + 1) - 1) / rentIncreaseRate)).toFixed(2)),
    }));
  };
  
  // const rentValues = calculateFutureRentExpenses(rentAmount, rentIncreaseRate, years);
  const rentValues = [
    { x: 1, y: 10000 },
    { x: 5, y: 50000 },
    { x: 10, y: 100000 },
    { x: 15, y: 150000 },
    { x: 20, y: 200000 },
    { x: 25, y: 250000 },
    { x: 30, y: 300000 },
  ];

  const data = [
    {
      id: `Own (${(annualAppreciationRate * 100).toFixed(1)}% annual appreciation)`,
      color: "hsl(217, 70%, 50%)",
      data: ownNetWealthValues,
    },
    {
      id: "Rent",
      color: "hsl(0, 70%, 50%)",
      data: rentValues, // Updated to reflect net wealth after rent
    },
    {
      id: "S&P 500 (10% increase/year)",
      color: "hsl(120, 70%, 50%)",
      data: sp500Values,
    },
  ];
  
  const showRentComparison = true;

  const filteredData = showRentComparison
  ? data
  : data.filter((item) => item.id !== "Rent");

  return (
    <div style={{ height: "500px", width: "100%", marginBottom: "50px" }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Rent vs Own vs S&P 500 Growth Over 30 Years
      </h2>
      <ResponsiveLine
  data={filteredData}
  margin={{ top: 50, right: 80, bottom: 50, left: 100 }}  // Increased left margin to avoid overlap
  xScale={{ type: "linear" }}
  yScale={{ type: "linear", min: 0, max: "auto", stacked: false, reverse: false }}
  axisBottom={{
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Years",
    legendPosition: "middle",
    legendOffset: 36,
    format: value => Math.round(value),  // Removing decimal places
  }}
  axisLeft={{
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Value ($)",
    legendPosition: "middle",
    legendOffset: -70,  // Adjust for spacing
    format: value => Number(value.toFixed(0)).toLocaleString(),  // Rounded to remove decimals
  }}        
  axisRight={null}
  colors={{ scheme: "nivo" }}
  pointSize={8}
  pointColor={{ from: "color" }}
  pointBorderWidth={2}
  pointBorderColor={{ from: "serieColor" }}
  enableSlices="x"
  useMesh={true}
  legends={[
    {
      anchor: "top-left",
      direction: "column",
      justify: false,
      translateX: 0,
      translateY: 0,
      itemWidth: 80,
      itemHeight: 20,
      itemsSpacing: 5,
      symbolSize: 12,
      symbolShape: "circle",
      itemDirection: "left-to-right",
      itemTextColor: "#333",
    },
  ]}
  tooltip={({ point }) => (
    <div
      style={{
        background: 'white',
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px'
      }}
    >
      <strong style={{ color: point.serieColor }}>
        {point.serieId}:
      </strong>{" "}
      {point.data.xFormatted} years: ${point.data.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    </div>
  )}
/>
    </div>
  );
};

RentOwnSP500Chart.propTypes = {
  home: PropTypes.shape({
    home_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    rent_amount_monthly: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  annualAppreciationRate: PropTypes.number.isRequired,
  assumptions: PropTypes.shape({
    current_rent: PropTypes.number,
    annual_rent_increase: PropTypes.number,
    monthly_rent_insurance: PropTypes.number,
    other_monthly_rent_expense: PropTypes.number,
  }).isRequired,
};

export default RentOwnSP500Chart;