import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  contactInfo: {}, // Store contact info by client_id
  selected_contact_details: {
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    website_url: '',
    start_now_url: '',
    phone_number: '',
    cell_phone: '',
    fax: '',
    business_street_address: '',
    city: '',
    state: '',
    zip: '',
  },
};

export const userContactSlice = createSlice({
  name: 'userContact',
  initialState,
  reducers: {
    setContactInfo: (state, action) => {
      const contactData = action.payload;

      if (contactData) {
        state.contactInfo = { [contactData.id]: contactData }; // Assuming one contact per user
        state.selected_contact_details = { ...contactData };
      }
    },
    updateSelectedContactForUser: (state, action) => {
      const clientId = action.payload;
      if (state.contactInfo[clientId]) {
        state.selected_contact_details = { ...state.contactInfo[clientId] };
      }
    },
    updateContactDetails: (state, action) => {
      state.selected_contact_details = { ...action.payload };
    },
    reset: () => initialState, // Reset to initial state
  },
});

export const { setContactInfo, updateSelectedContactForUser, updateContactDetails, reset } =
  userContactSlice.actions;
export default userContactSlice.reducer;
