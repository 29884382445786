import React, { useState, useEffect } from 'react';
import { changeEmail, changePassword, deleteAccount, getCurrentEmail } from './actions/accountActions'; // Ensure `getCurrentEmail` is implemented
import './Account.css';

const Account = () => {
  const [currentEmail, setCurrentEmail] = useState(''); // To store the current email
  const [newEmail, setNewEmail] = useState('');
  const [emailPassword, setEmailPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [deletePassword, setDeletePassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);

  // Separate status messages for each form
  const [emailStatusMessage, setEmailStatusMessage] = useState('');
  const [passwordStatusMessage, setPasswordStatusMessage] = useState('');
  const [deleteStatusMessage, setDeleteStatusMessage] = useState('');
  const [modal, setModal] = useState({ show: false, action: null });

  // Fetch current email on component mount
  useEffect(() => {
    const fetchCurrentEmail = async () => {
      try {
        const result = await getCurrentEmail();
        if (result.success) {
          setCurrentEmail(result.email);
        } else {
          setEmailStatusMessage(result.message || 'Failed to fetch current email.');
        }
      } catch (error) {
        console.error('Failed to fetch current email:', error);
        setEmailStatusMessage('An error occurred while fetching email.');
      }
    };

    fetchCurrentEmail();
  }, []);

  const openModal = (action) => setModal({ show: true, action });
  const closeModal = () => setModal({ show: false, action: null });

  const handleConfirm = () => {
    if (modal.action) modal.action();
    closeModal();
  };

  const handleSubmit = async (action, formData, setMessage) => {
    const result = await action(formData);
    setMessage(result.message);
  };

  const handleEmailSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setEmailStatusMessage("Authorization token is missing. Please log in.");
      return;
    }
  
    const formData = {
      new_email: newEmail,
      password: emailPassword,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/change_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the JWT token
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
      if (response.ok) {
        setEmailStatusMessage("Verification email sent. Please check your inbox on both your old and new email to confirm the change");
      } else {
        setEmailStatusMessage(data.msg || "Failed to send verification email.");
      }
    } catch (error) {
      console.error("Error:", error);
      setEmailStatusMessage("An error occurred. Please try again later.");
    }
  };
  
  
  

  const validatePassword = (password) => {
    const requirements = [
      { test: /.{8,}/, message: "Password must be at least 8 characters long." },
      { test: /[A-Z]/, message: "Password must contain at least one uppercase letter." },
      { test: /[a-z]/, message: "Password must contain at least one lowercase letter." },
      { test: /[0-9]/, message: "Password must contain at least one digit." },
      { test: /[!@#$%^&*(),.?":{}|<>]/, message: "Password must contain at least one special character." },
    ];

    for (const requirement of requirements) {
      if (!requirement.test.test(password)) {
        return requirement.message;
      }
    }
    return null;
  };

  const handlePasswordSubmit = () => {
    const error = validatePassword(newPassword);
    if (error) {
      setPasswordStatusMessage(error);
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setPasswordStatusMessage('New passwords do not match.');
      return;
    }

    const formData = new FormData();
    formData.set('oldPassword', oldPassword);
    formData.set('newPassword', newPassword);
    handleSubmit(changePassword, formData, setPasswordStatusMessage);
  };

  const handleDeleteSubmit = () => {
    const formData = new FormData();
    formData.set('deletePassword', deletePassword);
    handleSubmit(deleteAccount, formData, setDeleteStatusMessage);
  };

  return (
    <div className="account-container">
      <h1 className="account-title">Account Settings</h1>

      <form onSubmit={(e) => e.preventDefault()}>
        <h3>Change Email</h3>
        <p>Current Email: <strong>{currentEmail}</strong></p>
        <div className="form-group">
          <input
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="New Email"
            required
          />
          <input
            type="password"
            value={emailPassword}
            onChange={(e) => setEmailPassword(e.target.value)}
            placeholder="Current Password"
            required
          />
        </div>
        {emailStatusMessage && <p className="status-message">{emailStatusMessage}</p>}
        <button type="button" onClick={() => openModal(handleEmailSubmit)}>Change Email</button>
      </form>

      <form onSubmit={(e) => e.preventDefault()}>
        <h3>Change Password</h3>
        <div className="form-group">
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder="Old Password"
            required
          />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
              const error = validatePassword(e.target.value);
              setPasswordStatusMessage(error || '');
            }}
            placeholder="New Password"
            required
          />
          <input
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            placeholder="Confirm New Password"
            required
          />
        </div>
        {passwordStatusMessage && <p className="status-message">{passwordStatusMessage}</p>}
        <button type="button" onClick={() => openModal(handlePasswordSubmit)}>Change Password</button>
      </form>

      <form onSubmit={(e) => e.preventDefault()}>
        <h3>Delete Account</h3>
        <div className="form-group">
          <input
            type="password"
            value={deletePassword}
            onChange={(e) => setDeletePassword(e.target.value)}
            placeholder="Password"
            required
          />
          <label className="delete-checkbox">
            <input
              type="checkbox"
              checked={confirmDelete}
              onChange={(e) => setConfirmDelete(e.target.checked)}
            />
            <span>I understand this action is irreversible</span>
          </label>
        </div>
        {deleteStatusMessage && <p className="status-message">{deleteStatusMessage}</p>}
        <button
          type="button"
          onClick={() => openModal(handleDeleteSubmit)}
          disabled={!confirmDelete}
          className="delete-button"
        >
          Delete Account
        </button>
      </form>

      {modal.show && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Are you sure?</h3>
            <p>This action cannot be undone. Please confirm.</p>
            <div className="modal-actions">
              <button onClick={handleConfirm} className="modal-confirm">Confirm</button>
              <button onClick={closeModal} className="modal-cancel">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;
