import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatNumber } from '../../utils/mathUtils'; // Utility function
// import './ComparisonSection.css';

const ComparisonSection = ({ annualAppreciationRate, assumptions }) => {
  const dispatch = useDispatch();

  // Access home details from Redux
  const loanDetails = useSelector((state) => state.home.selected_home_details);
  const years = loanDetails?.term_months / 12 || 30; // Default to 30 years
  const rentAmount = assumptions.current_rent || 0; // Rent amount
  const rentIncreases = assumptions.annual_rent_increase / 100; 

  const [comparisonResults, setComparisonResults] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [expandedRentRow, setExpandedRentRow] = useState(null);
  const [expandedWealthRow, setExpandedWealthRow] = useState(null);


  // Future Rent Expenses Calculation
  const calculateFutureRentExpenses = (rentAmount, rentIncreases, years) => {
    return 12 * rentAmount * ((Math.pow(1 + rentIncreases, years) - 1) / rentIncreases);
  };

  const calculateMonthlyPayment = (loanAmount, annualInterestRate, termMonths) => {
    const monthlyRate = annualInterestRate / 12 / 100;
    return (
      (loanAmount * monthlyRate) /
      (1 - Math.pow(1 + monthlyRate, -termMonths))
    );
  };
  
  const calculateOutstandingLoanBalance = (loanAmount, annualInterestRate, termMonths, elapsedMonths) => {
    const monthlyRate = annualInterestRate / 12 / 100;
    if (elapsedMonths >= termMonths) return 0; // Loan fully paid off
  
    const monthlyPayment = calculateMonthlyPayment(loanAmount, annualInterestRate, termMonths);
  
    return (
      loanAmount * Math.pow(1 + monthlyRate, elapsedMonths) -
      monthlyPayment * ((Math.pow(1 + monthlyRate, elapsedMonths) - 1) / monthlyRate)
    );
  };
  
  const calculateTotalPITI = (monthlyPayment, propertyTaxes, insurance, pmi, hoa) => {
    return monthlyPayment + propertyTaxes + insurance + pmi + hoa;
  };
  

  useEffect(() => {
    if (loanDetails) {
      const specificYears = [1, 5, 10, 15, 20, 25, 30]; // Specific years to calculate for
      const loanAmount = loanDetails.home_price - loanDetails.down_payment; // Calculate loan amount
      const annualInterestRate = loanDetails.interest_rate; // Annual interest rate
      const termMonths = loanDetails.term_months || 360; // Default to 30 years if term not specified
  
      const results = specificYears.map((year) => {
        const elapsedMonths = year * 12; // Convert current year to elapsed months
        const homeValue = loanDetails.home_price * Math.pow(1 + annualAppreciationRate, year); // Home value after appreciation
        const outstandingLoanBalance = calculateOutstandingLoanBalance(
          loanAmount,
          annualInterestRate,
          termMonths,
          elapsedMonths
        ); // Outstanding loan balance
        const rentExpense = calculateFutureRentExpenses(rentAmount, rentIncreases, year); // Rent expense
        const sp500Value = loanDetails.down_payment * Math.pow(1 + 0.1, year); // S&P 500 value (assuming 10% annual growth)
        const netWealth = homeValue - outstandingLoanBalance; // Net wealth calculation
  
        return {
          years: year,
          details: {
            rentAmount,
            rentIncreases,
            homeValue,
            outstandingLoanBalance,
          },
          rentExpense,
          sp500Value,
          netWealth,
        };
      });
  
      setComparisonResults(results);
    }
  }, [loanDetails, rentAmount, rentIncreases, annualAppreciationRate]);
  
  
  // Chart Data Generation
  useEffect(() => {
    if (comparisonResults.length > 0) {
      const labels = comparisonResults.map((result) => `${result.years} Years`);
      const rentExpenses = comparisonResults.map((result) => -result.rentExpense);
      const netWealth = comparisonResults.map((result) => result.netWealth);
      const sp500Values = comparisonResults.map((result) => result.sp500Value);

      setChartData({
        labels,
        datasets: [
          {
            label: 'Rent Expense (Negative)',
            backgroundColor: '#FF6384',
            data: rentExpenses,
          },
          {
            label: 'Own Net Wealth (Positive)',
            backgroundColor: '#36A2EB',
            data: netWealth,
          },
          {
            label: 'SP500 Value (Neutral)',
            backgroundColor: '#FFCE56',
            data: sp500Values,
          },
        ],
      });
    }
  }, [comparisonResults]);

  // Chart Options
  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => value.toLocaleString(),
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  // Rent Expansion Content
  const renderRentExpansion = (rowData) => (
    <div className="comparison-box">
      <p>Years: {rowData.years}</p>
      <p>Rent Amount: ${formatNumber(rowData.details.rentAmount)}</p>
      <p>Rent Increases: {formatNumber(rowData.details.rentIncreases * 100)}%</p>
      <p>Future Rent Expense: ${formatNumber(rowData.rentExpense)}</p>
    </div>
  );

  // Wealth Expansion Content
  const renderWealthExpansion = (rowData) => (
    <div className="comparison-box">
      <p>Years: {rowData.years}</p>
      <p>Home Value Over Time: ${formatNumber(rowData.details.homeValue)}</p>
      <p>Outstanding Loan Balance: ${formatNumber(rowData.details.outstandingLoanBalance)}</p>
      <p>Net Wealth: ${formatNumber(rowData.netWealth)}</p>
    </div>
  );

  return (
    <div className="comparison-section">
      <h1>Rent vs Own Comparison</h1>

      {/* {chartData && (
        <div className="p-chart" style={{ height: '600px', width: '100%' }}>
          <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
      )} */}

      <div className="comparison-table">
        <DataTable value={comparisonResults} responsiveLayout="scroll">
          <Column field="years" header="Years" />
          <Column
            header="Future Total Rent Expenses"
            body={(rowData) => (
              <>
                <span
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() =>
                    setExpandedRentRow(expandedRentRow === rowData.years ? null : rowData.years)
                  }
                >
                  ${formatNumber(rowData.rentExpense)}
                  <i
                    className={`pi pi-chevron-${
                      expandedRentRow === rowData.years ? 'down' : 'right'
                    }`}
                    style={{ marginLeft: '10px' }}
                  ></i>
                </span>
                {expandedRentRow === rowData.years && renderRentExpansion(rowData)}
              </>
            )}
          />
          <Column
            header="Own Net Wealth"
            body={(rowData) => (
              <>
                <span
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() =>
                    setExpandedWealthRow(expandedWealthRow === rowData.years ? null : rowData.years)
                  }
                >
                  ${formatNumber(rowData.netWealth)}
                  <i
                    className={`pi pi-chevron-${
                      expandedWealthRow === rowData.years ? 'down' : 'right'
                    }`}
                    style={{ marginLeft: '10px' }}
                  ></i>
                </span>
                {expandedWealthRow === rowData.years && renderWealthExpansion(rowData)}
              </>
            )}
          />
          <Column
            header="SP500 Value"
            body={(rowData) => (
              <span style={{ color: 'green' }}>
                ${formatNumber(rowData.sp500Value)}
              </span>
            )}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default ComparisonSection;
